import { Injectable, signal, WritableSignal } from '@angular/core';
import { GetObjectsResponse } from '@dotocean/virtualworld-ngx-services';

@Injectable()
export class MapHistoryService {
  public objectsForReplay: WritableSignal<GetObjectsResponse[]> = signal([]);

  public replayMode = signal(false);

  constructor() {}

  public cancelReplayMode() {
    this.replayMode.set(false);
    this.objectsForReplay.set([]);
  }

  public addObjectToReplay(obj: GetObjectsResponse) {
    if (this.objectsForReplay().find((objForReplay) => objForReplay.oid === obj.oid) === undefined) {
      this.replayMode.set(true);
      this.objectsForReplay.set([...this.objectsForReplay(), obj]);
    }
  }

  public removeObjectFromReplay(mmsi: number) {
    this.objectsForReplay.set(this.objectsForReplay().filter((objForReplay) => objForReplay.oid !== mmsi));

    if (this.objectsForReplay().length <= 0) {
      this.cancelReplayMode();
    }
  }
}
