import { Component, ElementRef, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import moment from 'moment';
import L from 'leaflet';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';
import { ToastService } from '../../services/toast-service';
import { MapService } from '../../services/map.service';
import { FleetManagerComponents } from '../../shared/fleetmanager-components.module';
import { ThemeService } from '../../services/theme-service';
import { AlertClient, Alert, AlertList, AlertSourceDto, AlertTypeDto, UpdateAlertRequest } from '../../services/apis/cloud.service';
import { ComponentHeaderComponent } from '../shared/component-header/component-header.component';
// eslint-disable-next-line no-var
declare var bootstrap: any;

@Component({
  selector: 'app-alerts',
  standalone: true,
  providers: [MapService],
  imports: [FleetManagerComponents, ComponentHeaderComponent],
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnDestroy, AfterViewInit {
  @ViewChild('inputFile') inputFile: ElementRef;

  public AlertSourceDto = AlertSourceDto;
  public AlertTypeDto = AlertTypeDto;
  public modal: any;
  public alerts: AlertList[] = [];
  public alert: Alert = {};
  public currentStep = 1;
  public moment = moment;
  public isLoading = false;
  public activeTheme = 'light';

  constructor(
    private alertClient: AlertClient,
    private toastService: ToastService,
    private authService: AtlantisNgxAuthService,
    public mapService: MapService,
    private readonly themeService: ThemeService
  ) {
    themeService.activeTheme$.subscribe((theme) => {
      this.activeTheme = theme;
    });

    this.loadAlerts();
  }

  private loadAlerts() {
    this.isLoading = true;
    this.alertClient.getAlerts(undefined).subscribe((result) => {
      this.alerts = result;
      this.isLoading = false;
    });
  }

  public updateAlert(alert: Alert) {
    const patchDocument = [{ op: 'replace', path: '/enabled', value: alert.enabled }];

    this.alertClient.updateAlert(alert.id!, patchDocument).subscribe(() => {
      this.toastService.showToast('Alert has been updated.');
    });
  }

  public addAlert() {
    this.alertClient.createAlert(this.alert as UpdateAlertRequest).subscribe(() => {
      this.closeModal();
      this.loadAlerts();

      this.toastService.showToast('Alert has been added.');
    });
  }

  public removeAlert(alert: AlertList) {
    this.alertClient.removeAlert(alert.id!).subscribe(() => {
      this.alerts.splice(this.alerts.indexOf(alert), 1);
      this.toastService.showToast('Alert has been removed.');
    });
  }

  public openModal(): void {
    this.alert = {
      type: AlertTypeDto.IN_APP,
      groupId: this.authService.identity?.Application?.Groups[0] ?? 1,
      source: AlertSourceDto.ALL,
    };

    this.currentStep = 1;
    this.modal.show();
  }

  public continue(): void {
    this.currentStep += 1;
    if (this.currentStep == 2) this.initMap();
  }

  public back(): void {
    this.currentStep -= 1;
    if (this.currentStep == 2) this.initMap();
  }

  public initMap(): void {
    if (this.mapService.Map != null) {
      setTimeout(() => this.mapService.Map?.invalidateSize(), 100);
    } else {
      this.mapService.initMap(true, 'alertMap');
      this.mapService.setDefaultView();
    }
  }

  public onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        const result = reader.result as string;
        const geoJSON = JSON.parse(result);

        const layer = L.geoJSON(geoJSON, { style: { fillColor: '#E1234A', color: '#E1234A', weight: 0.5 } });
        this.mapService.Map?.addLayer(layer);
        this.mapService.Map?.fitBounds(layer.getBounds());

        this.alert.polygon = JSON.stringify(geoJSON.features[0].geometry);
      };

      reader.readAsText(file);
    }
  }

  public closeModal(): void {
    this.mapService.Dispose();
    this.inputFile.nativeElement.value = '';
    this.modal.hide();
  }

  ngAfterViewInit(): void {
    this.modal = new bootstrap.Modal('#addAlertModal');
    const input = document.getElementById('alert-name');

    const element = document.getElementById('addAlertModal');

    element?.addEventListener('shown.bs.modal', () => {
      input?.focus();
    });
  }

  ngOnDestroy() {
    if (this.mapService != null) this.mapService.Dispose();
  }
}
