import { Component, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import L from 'leaflet';
import '@arl/leaflet-tracksymbol2';
import { Router } from '@angular/router';
import { ObjectsWebSocket, ObjectsWebSocketService } from '../../services/objects-websocket-service';
import { ObjectsApiService } from '../../services/objects-api-service';
import { NotificationService } from '../../services/shared-service';
import { MapService } from '../../services/map.service';
import { FleetManagerComponents } from '../../shared/fleetmanager-components.module';
import { ObjectClient, VirtualWorldObjectsWebSocket, VirtualworldNgxObjectsSocketService } from '@dotocean/virtualworld-ngx-services';
import { catchError, tap, of, zip, switchMap, filter } from 'rxjs';
import { AlertClient, AlertDetailedList, AssetClient, AssetList, AssetTypeDto } from '../../services/apis/cloud.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ObjectNavigation } from '../../models/object-navigation';
import { MapHoverComponent } from '../shared/map-hover/map-hover.component';

@UntilDestroy()
@Component({
  selector: 'app-map',
  standalone: true,
  imports: [FleetManagerComponents, MapHoverComponent],
  providers: [MapService],
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  private _vessels: AssetList[] = [];
  private _markers: any = {};
  private _boundMarkers: any = {};
  private _objectsSocket?: ObjectsWebSocket;
  private _boundsSocket?: VirtualWorldObjectsWebSocket;

  public alerts: AlertDetailedList[] = [];

  constructor(
    private readonly alertClient: AlertClient,
    private readonly assetsClient: AssetClient,
    private readonly objectsService: ObjectsApiService,
    private readonly objectsSocketService: ObjectsWebSocketService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly mapService: MapService,
    private readonly boundsObjectsService: VirtualworldNgxObjectsSocketService,
    private readonly objectClient: ObjectClient
  ) {}

  public async ngOnInit() {
    if (!this.mapService.Map) await this.mapService.initMap(true);

    this.mapService.mapLoaded$
      .pipe(
        filter((loaded) => loaded),
        tap(() => {
          const savedState = this.loadMapState();
          if (savedState) this.mapService.Map?.setView([savedState.lat, savedState.lng], savedState.zoom);

          //Moved here so we set map stuff only when the map was loaded
          this.mapService.setBounds();
          this.getAlerts();
          this.getVessels();
          this.loadObjects$().subscribe();

          this.mapService.subscribeTo_MoveEnd(() => {
            this.saveMapState();
            this.updateBoundObjectsSocket();
            this.loadObjects$().subscribe();
          });

          this.notificationService.assetsChangedEvent$
            .pipe(
              untilDestroyed(this),
              tap(() => this.getVessels())
            )
            .subscribe();
        })
      )
      .subscribe();
  }

  private loadMapState(): { lat: number; lng: number; zoom: number } | undefined {
    const savedState = localStorage.getItem('mapBounds');
    if (savedState) return JSON.parse(savedState);

    return undefined;
  }

  private saveMapState() {
    this.mapService.setBounds();

    const center = this.mapService.Map?.getCenter();
    const zoom = this.mapService.Map?.getZoom();
    const mapState = {
      lat: center?.lat,
      lng: center?.lng,
      zoom: zoom,
    };

    localStorage.setItem('mapBounds', JSON.stringify(mapState));
  }

  private connectToObjectsSocket(): void {
    if (!this._objectsSocket) {
      this._objectsSocket = this.objectsSocketService.createObjectsSocket(this._vessels.map((e: any) => e.uuid).join(','));
      this._objectsSocket.ObjectNavigationChanged.subscribe((assets) => this.updateAssets(assets));
      this._objectsSocket.connect();
    }
  }

  private updateBoundObjectsSocket(): void {
    const bounds = this.mapService.getBounds();
    if (!this._boundsSocket) {
      this._boundsSocket = this.boundsObjectsService.createObjectsSocket(bounds);
      this._boundsSocket.ObjectNavigationChanged.subscribe((objects) => this.updateObjects(objects));
      this._boundsSocket.ObjectDisappeared.subscribe((objects) => objects.forEach((obj) => this.objectDisappeared(obj)));
      this._boundsSocket.connect();
    } else {
      this.mapService.setBounds();
      this._boundsSocket.setBounds(this.mapService.getBounds());
    }
  }

  private objectDisappeared(object: any) {
    if (!object) return;

    const marker = this._boundMarkers[object.oid];
    if (marker != null) {
      this.mapService.removeLayer(marker);
      if (marker.poly != null) this.mapService.removeLayer(marker.poly);
      delete this._boundMarkers[object.oid];
    }
  }

  private loadObjects$() {
    const bounds = this.mapService.getBounds();

    return this.objectClient.getAll(bounds.latSW, bounds.lngSW, bounds.latNE, bounds.lngNE, undefined).pipe(
      tap((objects) => {
        for (const key of Object.keys(this._boundMarkers)) {
          if (!objects.find((e) => e.oid?.toString() === key)) this.objectDisappeared(this._boundMarkers[key].object);
        }

        this.updateObjects(objects);

        this.updateBoundObjectsSocket();
      }),
      catchError((err) => {
        console.log(err);
        return of(0);
      })
    );
  }

  private getAlerts() {
    if (this.mapService.Map == null) return;

    this.alertClient.getDetailedAlerts(true).subscribe((result) => {
      this.alerts = result;

      this.alerts.forEach((alert) => {
        if (!alert.polygon) return;

        const polygonLayer = L.geoJSON(JSON.parse(alert.polygon), {
          style: {
            color: '#E1234A',
            fillColor: '#E1234A',
            fillOpacity: 0.1,
            weight: 1,
          },
        })
          .bindPopup('Alert geofence zone: ' + alert.name)
          .addTo(this.mapService.Map!);

        polygonLayer.on('mouseover', () => polygonLayer.openPopup());
        polygonLayer.on('mouseout', () => polygonLayer.closePopup());
        polygonLayer.on('click', () => this.router.navigate(['alerts']));
        polygonLayer.bringToBack();
      });
    });
  }

  private getVessels() {
    Object.keys(this._markers).forEach((key: any) => {
      this._markers[key].remove();
      delete this._markers[key];
    });

    this.assetsClient.getAssets(AssetTypeDto.VESSEL).subscribe((result) => {
      this._vessels = result;
      const objects: any = [];

      const vesselGetSingleObjects$ = this._vessels
        .filter((vessel) => vessel.uuid)
        .map((vessel) =>
          this.objectsService.getSingleObject(vessel.uuid!).pipe(
            tap((result) => {
              objects.push(result);
            }),
            catchError(() => {
              return of(null); // Return a fallback value or empty observable
            })
          )
        );

      zip(vesselGetSingleObjects$.length > 0 ? vesselGetSingleObjects$ : [of([])])
        .pipe(
          tap(() => {
            //TODO: The getSingleObject.Subscribe is asynchronised, so the objects array will be empty Or will be partially loaded (If you are lucky you already have the objects, but you must be very lucky)
            this.updateAssets(objects);
            this.connectToObjectsSocket();
          }),
          switchMap(() => this.loadObjects$())
        )
        .subscribe();
    });
  }

  private async updateAssets(objects: ObjectNavigation[]) {
    objects.forEach((obj: ObjectNavigation) => {
      const ves = this._vessels.find((e: any) => e.uuid == obj.oid);
      if (!ves) return;

      const marker = this._markers[obj.oid];
      const popupHTML = `
            <div style="font-weight: bold; color: black; margin-bottom: 5px;">
                ${ves.name}<small style="color: gray;"> (${ves.uuid})</small>
            </div>
            <div style="color: black;">
                <p>Last update: ${moment
                  .unix(obj.time / 1000)
                  .local()
                  .format('DD/MM/YYYY HH:mm:ss')}</p>
                <div><b>Course:</b> ${obj.c} °</div>
                <div><b>Heading:</b> ${obj.h} °</div>
                <div><b>True heading:</b> ${obj.t_h} °</div>
            </div>
        `;

      if (marker == null) {
        if (this.mapService.Map == null) return;

        const popup = L.popup({
          closeButton: false,
          autoClose: false,
          offset: [0, -60],
        }).setContent(popupHTML);

        const icon = L.divIcon({
          className: ves.owned ? 'markerVessel' : 'markerVesselFavorite',
          iconAnchor: [23.5, 60],
          iconSize: [46.98, 60],
        });

        this._markers[ves.uuid!] = L.marker([obj.lat, obj.lng], { icon: icon }).bindPopup(popup).addTo(this.mapService.Map);

        this._markers[ves.uuid!].on('mouseover', () => this._markers[ves.uuid!].openPopup());
        this._markers[ves.uuid!].on('mouseout', () => this._markers[ves.uuid!].closePopup());
        this._markers[ves.uuid!].on('click', () => this.router.navigate(['vessels', ves.id]));
      } else {
        marker.setLatLng([obj.lat, obj.lng]);
        marker.getPopup().setContent(popupHTML);
      }
    });
  }

  private async updateObjects(objects: any) {
    objects.forEach((obj: any) => {
      if (this._markers[obj.oid] != null) return;

      const marker = this._boundMarkers[obj.oid];
      const popupHTML = `
            <div style="font-weight: bold; color: black; margin-bottom: 5px;">
                ${obj.name}<small style="color: gray;"> (${obj.oid})</small>
            </div>
            <div style="color: black;">
                <p>Last update: ${moment
                  .unix(obj.time / 1000)
                  .local()
                  .format('DD/MM/YYYY HH:mm:ss')}</p>
                <div><b>Speed:</b> ${Math.round(obj.s * 100) / 100} m/s or knots</div>
                <div><b>Course:</b> ${Math.round(obj.c * 100) / 100} °</div>
                <div><b>Heading:</b> ${Math.round(obj.h * 100) / 100} °</div>
                <div><b>True heading:</b> ${Math.round(obj.t_h * 100) / 100} °</div>
            </div>
        `;

      if (!marker) {
        if (!this.mapService.Map) return;

        const popup = L.popup({
          closeButton: false,
          autoClose: false,
          offset: [0, -15],
        }).setContent(popupHTML);

        let color = '';

        switch (obj.type) {
          case 'AIS':
            color = '#78D0E7';
            break;
          case 'CALYPSO':
            color = '#EC8E2F';
            break;
          case 'RADAR':
            color = '#E1234A';
            break;
        }

        const layer = (L as any).trackSymbol([obj.lat, obj.lng], {
          fill: true,
          fillColor: color,
          weight: 1,
          fillOpacity: 1,
          heading: (obj.h * Math.PI) / 180.0,
          course: (obj.c * Math.PI) / 180.0,
          speed: obj.s,
        });

        this.mapService.objectGroup?.addLayer(layer);
        this._boundMarkers[obj.oid] = layer;

        this._boundMarkers[obj.oid].bindPopup(popup);
        this._boundMarkers[obj.oid].on('mouseover', () => this._boundMarkers[obj.oid].openPopup());
        this._boundMarkers[obj.oid].on('mouseout', () => this._boundMarkers[obj.oid].closePopup());
        return;
      }

      marker.setLatLng([obj.lat, obj.lng]);
      marker.setHeading((obj.h * Math.PI) / 180.0);
      marker.setCourse((obj.c * Math.PI) / 180.0);
      marker.setSpeed(obj.s);

      marker.getPopup().setContent(popupHTML);
    });
  }

  ngOnDestroy() {
    this.mapService?.Dispose();
    if (this._objectsSocket) this._objectsSocket.disconnect();
    if (this._boundsSocket) this._boundsSocket.disconnect();
  }
}
