<div id="map" [class.map-draw]="mapMeasuringService.measuringActive()">
  @if(!mapHistoryService.replayMode()){ @if(mapService.mousePosition()){
  <div class="map--position">
    <div class="map--position-col">{{ convertToDMS(mapService.mousePosition()?.lat, true) }}</div>
    <div class="map--position-col">{{ convertToDMS(mapService.mousePosition()?.lng, false) }}</div>
  </div>
  }
  <div class="legend">
    <div>
      <img width="40" src="icons/vessel-owned.svg" alt="" />
      My fleet
    </div>
    <div style="margin-top: 5px">
      <img width="40" src="icons/vessel-favorite.svg" alt="" />
      Watchlist
    </div>
  </div>
  }
</div>

<app-map-object (colorChanged)="vesselTypeColorChanged($event)"></app-map-object>

@if(mapHistoryService.replayMode() && mapHistoryService.objectsForReplay().length > 0){
<app-map-history></app-map-history>
}

<app-map-options></app-map-options>

@if(mapMeasuringService.measuringActive()){
<app-map-measuring></app-map-measuring>
}
