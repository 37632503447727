<form class="needs-validation" (ngSubmit)="addAsset()" *ngIf="asset">
  <div class="modal-header">
    <h1 class="modal-title fs-5" id="exampleModalLabel">Add {{ fixedAssetType ?? "Asset" | lowercase }}</h1>
    <button (click)="activeModal.close(true)" type="button" class="btn-close" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div class="mb-3" *ngIf="!fixedAssetType">
      <div style="cursor: pointer" class="card-group">
        <div (click)="selectType(AssetTypeDto.VESSEL)" class="card text-center">
          <div [class.selected-asset-type]="asset.type === AssetTypeDto.VESSEL" class="card-body">
            <img themedImage srcLight="icons/vessel-dark.svg" srcDark="icons/vessel-light.svg" class="bi pe-none" width="40" height="40" alt="" />
            <h5 class="card-subtitle mt-2 text-body-secondary">Vessel</h5>
          </div>
        </div>
        <div (click)="selectType(AssetTypeDto.STATIONARY)" class="card text-center">
          <div [class.selected-asset-type]="asset.type === AssetTypeDto.STATIONARY" class="card-body">
            <img themedImage srcLight="icons/static-dark.svg" srcDark="icons/static-light.svg" class="bi pe-none" width="40" height="40" alt="" />
            <h5 class="card-subtitle mt-2 text-body-secondary">Stationary</h5>
          </div>
        </div>
        <div (click)="selectType(AssetTypeDto.VEHICLE)" class="card text-center">
          <div [class.selected-asset-type]="asset.type === AssetTypeDto.VEHICLE" class="card-body">
            <img themedImage srcLight="icons/vehicle-dark.svg" srcDark="icons/vehicle-light.svg" class="bi pe-none" width="40" height="40" alt="" />
            <h5 class="card-subtitle mt-2 text-body-secondary">Vehicle</h5>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="asset.type === AssetTypeDto.VESSEL" class="mb-3">
      <form role="search" class="mmsi-query">
        <app-vessel-search [selectedItem]="selectedVessel" (selectedItemChange)="selectedItem($event)"></app-vessel-search>
      </form>
    </div>
    <div class="mb-3">
      <label for="asset-name" class="col-form-label" [appAutoFocusCondition]="asset.type !== AssetTypeDto.VESSEL">Name*</label>
      <input #nameInput [(ngModel)]="asset.name" type="text" class="form-control" name="asset-name" id="asset-name" required="" />
    </div>
    <div class="mb-3">
      <label for="asset-mmsi" class="col-form-label">UUID</label>
      <input [(ngModel)]="asset.uuid" type="number" class="form-control" name="asset-mmsi" id="asset-mmsi" />
    </div>

    <div class="btn-group mb-2" role="group">
      <input
        [(ngModel)]="asset.owned"
        [value]="true"
        class="btn-check"
        type="radio"
        name="flexRadioDefault"
        [id]="fixedAssetType + 'flexRadioDefault1'"
      />
      <label
        [ngClass]="asset.owned === true ? 'radioActive' : activeTheme === 'light' ? 'radioInactiveLight' : 'radioInactiveDark'"
        class="btn btn-outline-secondary"
        [for]="fixedAssetType + 'flexRadioDefault1'"
      >
        My fleet
      </label>
      <input
        [(ngModel)]="asset.owned"
        [value]="false"
        class="btn-check"
        type="radio"
        name="flexRadioDefault"
        [id]="fixedAssetType + 'flexRadioDefault2'"
      />
      <label
        [ngClass]="asset.owned === false ? 'radioActive' : activeTheme === 'light' ? 'radioInactiveLight' : 'radioInactiveDark'"
        class="btn btn-outline-secondary"
        [for]="fixedAssetType + 'flexRadioDefault2'"
      >
        Watchlist
      </label>
    </div>

    <div *ngIf="groups.length > 1" class="mb-3">
      <label class="col-form-label">Group</label>
      <select name="asset-group" [(ngModel)]="asset.groupId" class="form-select" aria-label="Select group">
        <option *ngFor="let group of groups" [value]="group.Id">{{ group.Name }} ({{ group.Id }})</option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
    <button
      [class.btn-primary]="activeTheme === 'light'"
      [class.btn-light]="activeTheme === 'dark'"
      [disabled]="!asset.name"
      type="submit"
      class="btn"
    >
      Add {{ fixedAssetType ?? "Asset" | lowercase }}
    </button>
  </div>
</form>
