<div
  style="cursor: pointer"
  [ngClass]="isSelected ? 'active' : 'link-body-emphasis'"
  [routerLink]="[routerLink]"
  class="nav-link nav-link-light"
  aria-current="page"
>
  <ng-container *ngIf="bootstrapIcon; else themedImage">
    <svg class="bi pe-none ms-1" width="20" height="20">
      <use [attr.href]="bootstrapIcon" />
    </svg>
  </ng-container>

  <i *ngIf="faIcon" class="pe-none ms-1 sidebar-item--fa" [class]="faIcon"></i>

  <ng-container *ngIf="!isCollapsed">
    <span class="ms-3">{{ title }}</span>
    <span *ngIf="badgeCount !== undefined" [ngClass]="activeTheme === 'light' ? 'text-bg-primary' : 'text-bg-light'" class="badge float-end">
      {{ badgeCount }}
    </span>
  </ng-container>
</div>

<ng-template #themedImage>
  <img
    *ngIf="iconLight && iconDark"
    themedImage
    [srcLight]="iconLight"
    [srcDark]="iconDark"
    [themeReverse]="activeTheme === 'dark' && isSelected"
    class="bi pe-none ms-1"
    width="20"
    height="20"
    alt=""
  />
</ng-template>
