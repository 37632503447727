import { Injectable, signal, WritableSignal } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { CerebroClient, Group } from './apis/cloud.service';

@Injectable()
export class CerebroApiService {
  public url = 'https://api.dotocean.eu/cerebro/';
  public groups$: Observable<Group[]>;
  constructor(cerebroClient: CerebroClient) {
    this.groups$ = cerebroClient.getGroups().pipe(shareReplay(1));
  }
}
