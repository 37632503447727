import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private eventSource = new Subject<ToastMessage>();
  public showToastEvent$ = this.eventSource.asObservable();

  public showToast(message: string) {
    this.eventSource.next(ToastMessage.Create(message));
  }

  public showWarning(message: string) {
    this.eventSource.next(ToastMessage.CreateWarning(message));
  }

  public showError(message: string) {
    this.eventSource.next(ToastMessage.CreateError(message));
  }
}

export class ToastMessage {
  public message: string;
  public type: 'default' | 'warn' | 'error' = 'default';

  constructor(message: string, type: 'default' | 'warn' | 'error') {
    this.message = message;
    this.type = type;
  }

  public static Create(message: string) {
    return new ToastMessage(message, 'default');
  }
  public static CreateError(message: string) {
    return new ToastMessage(message, 'error');
  }
  public static CreateWarning(message: string) {
    return new ToastMessage(message, 'warn');
  }
}
