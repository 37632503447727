import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { WebrtcService } from '../../../services/webrtc-service';
import { FleetManagerComponents } from '../../../shared/fleetmanager-components.module';
import { FunctionsUsingCSI, NgTerminal, NgTerminalModule } from 'ng-terminal';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { ModalComponent } from '../../modal/modal.component';
import { JoystickEvent, NgxJoystickComponent } from '../../../shared/ngx-joystick/ngx-joystick.component';
import { JoystickManagerOptions } from 'nipplejs';
import moment from 'moment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConfigApiService } from '../../../services/config-api-service';
import { CameraApiService } from '../../../services/camera-api-service';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';
import { NVRApiService } from '../../../services/nvr-service';
import { MqttService } from '../../../services/mqtt-service';
import { CerebroApiService } from '../../../services/cerebro-api-service';
import { finalize, of, tap } from 'rxjs';
import { ThemeService } from '../../../services/theme-service';
import { AssetClient, Group, ModuleTypeDto } from '../../../services/apis/cloud.service';
import { AssetExtended, fromAsset } from '../../../models/asset-extended';
import { ModuleExtended } from '../../../models/module-extended';
import { AssetsDetailRadarComponent } from './assets-detail-radar/assets-detail-radar.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetsDetailEditComponent } from './assets-detail-edit/assets-detail-edit.component';
import { AssetsDetailModulesComponent } from './assets-detail-modules/assets-detail-modules.component';
// eslint-disable-next-line no-var
declare var bootstrap: any;

@Component({
  selector: 'app-asset-detail',
  standalone: true,
  providers: [WebrtcService],
  imports: [FleetManagerComponents, NgTerminalModule, MonacoEditorModule, ModalComponent, AssetsDetailRadarComponent, AssetsDetailModulesComponent],
  templateUrl: './assets-detail.component.html',
  styleUrl: './assets-detail.component.scss',
})
export class AssetDetailComponent implements AfterViewInit, OnDestroy {
  @ViewChild('term', { static: false }) child?: NgTerminal;
  @ViewChild('joystick') staticJoystick!: NgxJoystickComponent;

  public ModuleTypeDto = ModuleTypeDto;

  public joystickOptions: JoystickManagerOptions = {
    mode: 'static',
    position: { bottom: '80px', right: '50%' },
    color: '#86D9F0',
  };

  public asset?: AssetExtended;
  public selectedTab = 'submodules';
  public selectedModule?: ModuleExtended;

  public editorOptions = {
    theme: 'vs-dark',
    language: 'json',
    automaticLayout: true,
    formatOnPaste: true,
    formatOnType: true,
  };
  public enlargeThumbnailModal: any;
  public loadingModal: any;
  public consoleModal: any;
  public clipModal: any;
  public subModuleLoading = false;
  public groups: Group[] = [];
  public configs: any = [];
  public moment: any = moment;
  public selectedImage = '';
  public selectedEventId = '';
  public totalPages = 0;
  public nvrURL: SafeResourceUrl;
  public clipURL: SafeResourceUrl;
  public numberOfDaysStored = 25;
  public assetLoading = false;
  public showConfirm = false;
  readonly prompt = '\n' + FunctionsUsingCSI.cursorColumn(1) + '$ ';

  public activeTheme = 'light';

  constructor(
    private readonly assetsClient: AssetClient,
    private readonly route: ActivatedRoute,
    private readonly configService: ConfigApiService,
    private readonly cameraService: CameraApiService,
    public readonly authService: AtlantisNgxAuthService,
    public readonly nvrService: NVRApiService,
    private readonly sanitizer: DomSanitizer,
    private readonly mqttService: MqttService,
    private readonly webrtcService: WebrtcService,
    private readonly cerebroService: CerebroApiService,
    public readonly themeService: ThemeService,
    private readonly modalService: NgbModal
  ) {
    this.loadAsset();

    this.cerebroService.groups$.pipe(tap((groups) => (this.groups = groups))).subscribe();

    themeService.activeTheme$.subscribe((theme) => {
      this.activeTheme = theme;
    });
  }

  private loadAsset() {
    this.assetLoading = true;

    this.assetsClient
      .getAsset(this.route.snapshot.params['id'])
      .pipe(
        tap((response) => {
          this.asset = fromAsset(response);

          if (this.asset.modules) {
            for (const module of this.asset.modules) {
              if (module.type === ModuleTypeDto.CAMERA) this.getEvents(module);
            }
          }

          if (this.asset.modules.length > 0) this.selectModule(this.asset.modules[0]);

          setTimeout(() => {
            const tooltipTriggerList: any = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            [...tooltipTriggerList].forEach((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));
          }, 200);
        }),
        finalize(() => (this.assetLoading = false))
      )
      .subscribe();
  }

  private async getConfigs(module: ModuleExtended) {
    if (module.type === ModuleTypeDto.CAMERA) {
      this.editorOptions.language = 'json';

      try {
        const config = JSON.stringify(await this.nvrService.getConfig(module.parsedConfig), null, 2);
        module.selectedConfigFile = { content: config };
      } catch {
        module.selectedConfigFile = { content: '' };
      }
    } else if (module.guid != null) {
      this.editorOptions.language = 'yaml';
      module.configFiles = await this.configService.getConfigFiles(module.guid);

      if (module.configFiles && module.configFiles.length > 0) module.selectedConfigFile = module.configFiles[0];
      else module.selectedConfigFile = { content: '' };
    }
  }

  private getEvents(module: any) {
    if (module.guid != null) {
      module.selectedEventPage = 1;
      this.cameraService.getEvents(module.guid).subscribe((result) => {
        module.events = result;
        module.events.sort((a: any, b: any) => b.timestamp - a.timestamp);
        this.updateEventsList(module);
      });
    }
  }

  public updateEventsList(module: any) {
    module.shownEvents = module.events.slice((module.selectedEventPage - 1) * 5, module.selectedEventPage * 5);
  }

  get pageNumbers(): number[] {
    if (!this.selectedModule) return [0];

    const halfRange = 4;
    this.totalPages = Math.ceil((this.selectedModule.events?.length ?? 1) / 5);
    let start = Math.max(1, this.selectedModule.selectedEventPage - halfRange);
    const end = Math.min(start + 8, this.totalPages);

    if (end === this.totalPages) start = Math.max(1, end - 8);

    const pageNumbers = Array.from({ length: Math.min(9, this.totalPages) }, (_, i) => start + i);
    return pageNumbers;
  }

  public async saveAndPush() {
    //TODO: WHERE TF DOES .guid come from???
    if (!this.selectedModule || !this.selectedModule.guid) return;

    this.loadingModal.show();

    await this.configService.saveAndPushConfigFiles(this.selectedModule.guid, this.selectedModule.configFiles);

    this.loadingModal.hide();
  }

  public async generateConfigs() {
    //TODO: WHERE TF DOES .guid come from???
    if (!this.selectedModule || !this.selectedModule.guid) return;

    this.loadingModal.show();

    await this.configService.generateConfigsFromTemplates(this.selectedModule.guid, this.selectedModule);
    await this.getConfigs(this.selectedModule);

    this.loadingModal.hide();
  }

  public async openUpdateAsset() {
    const modalRef = this.modalService.open(AssetsDetailEditComponent);
    modalRef.componentInstance.editAsset = { ...this.asset };

    modalRef.closed.subscribe((result: boolean) => {
      if (result) {
        this.loadAsset();
      }
    });
  }

  public async openEnlargeThumbnailModal() {
    this.enlargeThumbnailModal.show();
  }

  public loadNvrURL(): void {
    if (!this.selectedModule) return;
    this.nvrURL = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.selectedModule.parsedConfig.address}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public openClipModal(eventId: string): void {
    // this.clipURL = this.sanitizer.bypassSecurityTrustResourceUrl(`http://localhost:4200/api/events/${eventId}/clip.mp4`);
    // this.clipURL = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.selectedModule.parsedConfig.address}/api/events/${eventId}/clip.mp4`);
    // this.clipModal.show();
  }

  public async openConsoleModal() {
    // TODO: connect to selectedmodule through ssh
    if (!this.child) return;

    this.child.underlying?.clear();
    this.child.write(FunctionsUsingCSI.cursorColumn(0) + '$ ');

    setTimeout(() => {
      this.child?.underlying?.focus();
    }, 500);

    this.consoleModal.show();
  }

  public async addSubModule(submodule: string) {
    if (!this.selectedModule) return;

    this.subModuleLoading = true;

    this.selectedModule.parsedConfig.subModules[submodule] = true;
    this.selectedModule.config = JSON.stringify(this.selectedModule.parsedConfig);

    this.updateModule$().subscribe(() => (this.subModuleLoading = false));
  }

  private updateModule$() {
    if (!this.asset || !this.selectedModule) return of();

    const patchOperations = [
      { op: 'replace', path: '/name', value: this.selectedModule.name },
      { op: 'replace', path: '/config', value: this.selectedModule.config },
    ];

    return this.assetsClient.updateModule(this.asset.id!, this.selectedModule.id!, patchOperations);
  }

  public async removeSubModule(submodule: string) {
    if (!this.selectedModule) return;

    this.subModuleLoading = true;

    delete this.selectedModule.parsedConfig.subModules[submodule];
    this.selectedModule.config = JSON.stringify(this.selectedModule.parsedConfig);

    this.updateModule$().subscribe(() => (this.subModuleLoading = false));
  }

  public refreshModule(module: ModuleExtended) {
    switch (module.type) {
      case ModuleTypeDto.AYB:
        break;
      case ModuleTypeDto.CAMERA:
        if (module.parsedConfig.source == 'WEBRTC') this.stopVideo();
        if (module.parsedConfig.source == 'NVR') this.loadNvrURL();
        break;
      // case ModuleTypeDto.RADAR:
      //   if (this.selectedModule!.radarSocket) this.selectedModule!.radarSocket.disconnect();
      //   this.connectToRadar(this.selectedModule);
      //   break;

      //TODO: Reselect radar??
    }
  }

  public selectModule(module: ModuleExtended | undefined): void {
    this.selectedModule = module;

    if (!this.selectedModule) return;

    switch (this.selectedModule.type) {
      case ModuleTypeDto.AYB:
        this.selectedTab = 'submodules';
        break;
      case ModuleTypeDto.CAMERA:
        this.selectedTab = 'livestream';

        switch (this.selectedModule.parsedConfig.source) {
          case 'WEBRTC':
            setTimeout(() => {
              if (this.selectedModule!.webrtcConnection) {
                const videoElement = document.getElementById('videoElement' + module!.id) as HTMLVideoElement;
                this.selectedModule!.webrtcConnection.refreshSrcStream(videoElement);
              }
            }, 500);
            break;
          case 'NVR':
            this.loadNvrURL();
            break;
        }
        break;
      case ModuleTypeDto.RADAR:
        this.selectedTab = 'images';
        break;
    }
  }

  public startVideo() {
    if (!this.selectedModule) return;

    if (!this.mqttService.isConnected) this.mqttService.connect();

    const id = this.selectedModule.id;
    const name = this.selectedModule.parsedConfig.id;
    this.selectedModule.webRtcGuid = this.generateGuid();

    const videoElement = document.getElementById('videoElement' + id) as HTMLVideoElement;

    this.selectedModule.webrtcConnection = this.webrtcService.startConnection(`${name}/${this.selectedModule.webRtcGuid}`, videoElement);
  }

  public stopVideo() {
    if (this.selectedModule?.webrtcConnection) {
      this.webrtcService.stopConnection(`${this.selectedModule.parsedConfig.id}/${this.selectedModule.webRtcGuid}`);
      this.selectedModule.webrtcConnection = undefined;
    }
  }

  private _sendJoystickTimer: any;
  private _joystickData: any;

  public joystickMove(ev: JoystickEvent): void {
    this._joystickData = {
      pan: ev.data.vector.x / 20,
      tilt: ev.data.vector.y / 20,
    };

    if (this._sendJoystickTimer == null)
      this._sendJoystickTimer = setInterval(() => {
        this.selectedModule?.webrtcConnection?.sendMqttMessage({
          rostopic: 'axis_camera/camera/ptz/set_move/relative',
          rosmsg: `{ptz: {pan: ${this._joystickData.pan}, tilt: ${this._joystickData.tilt}, zoom:0.0}, ptz_vel: {pan: 0.0, tilt: 0.0, zoom:0.0}}`,
        });
      }, 200);
  }

  public joystickEnd(): void {
    if (this._sendJoystickTimer != null) {
      clearTimeout(this._sendJoystickTimer);
      this._sendJoystickTimer = null;
    }

    this.selectedModule?.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/set_move/relative',
      rosmsg: '{ptz: {pan: 0.0, tilt: 0.0, zoom: 0.0}, ptz_vel: 0.0, tilt: 0.0, zoom:0.0}}',
    });
  }

  public zoomIn(): void {
    this.selectedModule?.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/set_move/relative',
      rosmsg: '{ptz: {pan: 0.0, tilt: 0.0, zoom: 0.03}, ptz_vel: {pan: 0.0, tilt: 0.0, zoom:0.01}}',
    });
  }

  public zoomOut(): void {
    this.selectedModule?.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/set_move/relative',
      rosmsg: '{ptz: {pan: 0.0, tilt: 0.0, zoom: -0.03}, ptz_vel: {pan: 0.0, tilt: 0.0, zoom:0.01}}',
    });
  }

  public goToPreset(preset: string): void {
    this.selectedModule?.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/goto_preset',
      rosmsg: `{data: ${preset}}`,
    });
  }

  public activateTour(tour: string): void {
    this.selectedModule?.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/operate_preset_tour',
      rosmsg: `{name: ${tour}, action: start}`,
    });
  }

  public stopTour(): void {
    this.selectedModule?.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/stop',
      rosmsg: `{data: stop}`,
    });
  }

  ngAfterViewInit(): void {
    this.enlargeThumbnailModal = new bootstrap.Modal('#enlargeThumbnailModel');
    this.loadingModal = new bootstrap.Modal('#loadingModal');
    this.consoleModal = new bootstrap.Modal('#consoleModal');
    this.clipModal = new bootstrap.Modal('#clipModal');

    if (!this.child) return;

    this.child.setXtermOptions({
      fontFamily: '"Cascadia Code", Menlo, monospace',
      cursorBlink: true,
    });

    this.child.onData().subscribe((input) => {
      if (!this.child) return;

      if (input === '\r') {
        this.child.write(this.prompt);
      } else if (input === '\u007f') {
        if (this.child.underlying!.buffer.active.cursorX > 2) {
          this.child.write('\b \b');
        }
      } else if (input === '\u0003') {
        this.child.write('^C');
        this.child.write(this.prompt);
      } else this.child.write(input);
    });
  }

  public closeEnlargeThumbnailModal(): void {
    this.enlargeThumbnailModal.hide();
  }
  public closeConsoleModal(): void {
    this.consoleModal.hide();
  }
  public closeClipModal(): void {
    this.clipModal.hide();
  }

  private generateGuid(): string {
    return `${this.s4()}${this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}${this.s4()}${this.s4()}`;
  }

  private s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  ngOnDestroy(): void {
    if (this.mqttService != null && this.mqttService.isConnected) this.mqttService.disconnect();

    this.asset?.modules.forEach((module) => {
      if (module.radarSocket) module.radarSocket.disconnect();
      if (module.webrtcConnection) this.webrtcService.stopConnection(`${module.parsedConfig.id}/${module.webRtcGuid}`);
    });
  }
}
