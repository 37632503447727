import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';

@Injectable()
export class FleetManagerWebSocketService implements OnDestroy {
  public url = 'https://api.dotocean.eu/fleetmanager/';
  private _socket: FleetManagerWebSocket;

  constructor(private readonly authService: AtlantisNgxAuthService) {}

  public createFleetManagerSocket(): FleetManagerWebSocket {
    this._socket = new FleetManagerWebSocket(this.url, this.authService);
    return this._socket;
  }

  ngOnDestroy(): void {
    if (this._socket != null) this._socket.disconnect();
  }
}

export class FleetManagerWebSocket {
  public url: string;
  public AlertTriggered: Subject<any[]> = new Subject<any[]>();

  public isConnected?: boolean;
  private _isDisconnecting?: boolean;
  private readonly _connectionTimeout = 3000;
  private _socket: WebSocket;
  private _keepAlive: any;

  constructor(public rooturl: string, private readonly authService: AtlantisNgxAuthService) {}

  public connect() {
    this.reconnect();
  }

  private reconnect(): void {
    if (!this.authService.identity?.Session.SessionToken) {
      setTimeout(() => this.reconnect(), this._connectionTimeout);
      return;
    }

    let baseUrl = this.rooturl.replace('http', 'ws');
    baseUrl = baseUrl.endsWith('/') ? baseUrl : baseUrl + '/';
    this.url = baseUrl + `alert/ws?session=${encodeURIComponent(this.authService.identity.Session.SessionToken)}`;
    console.log(`Connecting to fleet manager socket`);

    this._socket = new WebSocket(this.url);
    this._socket.addEventListener('close', () => {
      this._socket.close();
      if (!this.isConnected && !this._isDisconnecting) console.log(this.url + ' is not reachable');
      else console.log(`Disconnected from fleet manager socket`);

      // only re-connect if not manually disconnected
      if (this.isConnected && !this._isDisconnecting) setTimeout(() => this.reconnect(), this._connectionTimeout);
      this.isConnected = false;

      if (this._keepAlive != null) {
        clearInterval(this._keepAlive);
        this._keepAlive = null;
      }
    });
    this._socket.addEventListener('open', () => {
      console.log(`Connected to fleet manager socket`);
      this.isConnected = true;

      this._keepAlive = setInterval(() => {
        if (this._socket.readyState == WebSocket.OPEN) this.send('ping');
      }, 30000);
    });
    this._socket.addEventListener('message', (event) => {
      this.onMessage(event);
    });
    this._socket.addEventListener('error', (event) => {
      console.error(`Error on fleet manager socket: ` + JSON.stringify(event));

      if (this._keepAlive != null) {
        clearInterval(this._keepAlive);
        this._keepAlive = null;
      }
    });
  }

  public send(message: string) {
    this._socket.send(message);
  }

  public disconnect(): any {
    console.log('Fleet manager socket service is disconnecting!');
    this.isConnected = false;
    this._isDisconnecting = true;
    if (this._socket != null) this._socket.close();
  }

  private onMessage(event: MessageEvent) {
    if (event.data === undefined) return;
    this.AlertTriggered.next(JSON.parse(event.data));
  }
}
