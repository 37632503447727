<div class="map-item" [class.map-item-unknown]="unknownVesselLocation" [style.left]="unknownVesselLocation ? '50%' : left + 'px'" [style.top]="unknownVesselLocation ? '50%' : top + 'px'">
  <div class="map-item--container">
    <div class="map-item--header">
      <ng-content select="[header]"></ng-content>
    </div>
    <div class="map-item--content">
      <ng-content></ng-content>
    </div>

    <div class="map-item--sideMenu">
      <ng-content select="[sideMenu]"></ng-content>
    </div>
  </div>
  <div class="map-item--footer prevent-select">
    <ng-content select="[footer]"></ng-content>
  </div>

  <div class="map-item-arrow"></div>
</div>
