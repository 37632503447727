<div style="height: 100%" class="d-flex align-items-center py-4 bg-body-tertiary">
  <div class="container text-center">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-10">
        <div class="wrap d-md-flex">
          <img src="images/vessel_swarm.png" alt="" width="400" height="400" />
          <form (ngSubmit)="login()" class="form-signin w-100 m-auto needs-validation">
            <img
              themedImage
              srcLight="icons/dotocean-cloud-dark.svg"
              srcDark="icons/dotocean-cloud-white.svg"
              class="bi pe-none mb-3"
              height="50"
              width="298"
              alt=""
            />
            <div style="display: block !important" *ngIf="invalidIdentity" class="invalid-feedback mb-3">Invalid email address or password!</div>
            <div class="form-floating">
              <input [(ngModel)]="email" type="email" class="form-control" id="email" name="email" placeholder="name@example.com" />
              <label for="email">Email address</label>
            </div>
            <div class="form-floating">
              <input [(ngModel)]="password" type="password" class="form-control" id="password" name="password" placeholder="Password" />
              <label for="password">Password</label>
            </div>
            <button *ngIf="(themeService.activeTheme$ | async) === 'light'" type="submit" [disabled]="isLoading" class="btn btn-primary w-100 py-2">
              <span *ngIf="isLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              <span *ngIf="!isLoading">Sign in</span>
            </button>
            <button *ngIf="(themeService.activeTheme$ | async) === 'dark'" type="submit" [disabled]="isLoading" class="btn btn-light w-100 py-2">
              <span *ngIf="isLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              <span *ngIf="!isLoading">Sign in</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
