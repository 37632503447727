import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root',
})
export class AppconfigService {
  private config?: AppConfig;

  loadConfig() {
    //Should not change! --> Seting the config values should be done in the config yaml file (See Calypso how)
    return fetch('config.json')
      .then((response) => response.json())
      .then((config) => {
        this.config = config;
      })
      .catch((err) => {
        console.error('Failed to load config', err);
      });
  }

  getConfig() {
    return this.config;
  }
}
