import { Injectable } from "@angular/core";
import { WebRTCConnection } from "../models/webRTCConnection";
import { MqttService } from "./mqtt-service";

@Injectable()
export class WebrtcService {
    private connections = new Map<string, WebRTCConnection>();

    constructor(private mqttService: MqttService) { }

    public startConnection(connectionId: string, videoElement: HTMLVideoElement): WebRTCConnection {
        const connection = new WebRTCConnection(this.mqttService, connectionId, videoElement);
        this.connections.set(connectionId, connection);

        this.mqttService.subscribe(connectionId).subscribe((message) => connection.handleMqttMessage(message));
        this.mqttService.publish(connectionId, 'yello');

        return connection;
    }

    public stopConnection(connectionId: string): void {
        const connection = this.connections.get(connectionId);
        if (connection) {
            connection.disconnect();
            this.connections.delete(connectionId);
        }
    }
}