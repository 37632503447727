<div class="container-fluid" *ngIf="type">
  <app-component-header
    [title]="type === AssetTypeDto.STATIONARY ? 'Stationaries' : (type | firstLetterCapital) + 's'"
    [newButtonText]="'Add ' + type | firstLetterCapital"
    (addNewClicked)="openModal()"
    (searching)="filterAssets($event)"
    [(isViewGridActive)]="isViewGridActive"
  ></app-component-header>

  <div *ngIf="assets().length === 0 && !isLoading" class="col-12 text-center">
    <div class="lead mt-5">
      There are currently no {{ type | lowercase }}s
      <br />
      <button type="button" class="btn btn-primary mt-3" (click)="openModal()">
        <svg style="vertical-align: -6px" class="bi pe-none" width="24" height="24">
          <use href="bootstrap-icons.svg#plus" />
        </svg>
        Add {{ type | firstLetterCapital }}
      </button>
    </div>
  </div>

  <div *ngIf="isLoading && isViewGridActive" class="col-sm-3">
    <div class="card h-100" aria-hidden="true">
      <svg
        class="bd-placeholder-img card-img-top"
        width="100%"
        height="180"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="Placeholder"
        preserveAspectRatio="xMidYMid slice"
        focusable="false"
      >
        <rect width="100%" height="100%" fill="#868e96"></rect>
      </svg>
      <div class="card-footer">
        <p class="card-text placeholder-glow">
          <span class="placeholder col-7"></span>
        </p>
      </div>
    </div>
  </div>

  <h3 *ngIf="ownedAssetsStatuses().length > 0" class="fw-bold mb-3 fs-4">
    <svg style="vertical-align: -2px" class="bi me-2" width="24" height="24">
      <use href="bootstrap-icons.svg#cart-check" />
    </svg>
    My fleet
  </h3>

  <ng-container [ngTemplateOutlet]="fleetOverviewTemplate" [ngTemplateOutletContext]="{ areOwnAssets: true }"></ng-container>

  <h3 *ngIf="favoriteAssetsStatuses().length > 0" class="fw-bold mt-3 mb-3 fs-4">
    <svg style="vertical-align: -4px" class="bi me-2" width="24" height="24">
      <use href="bootstrap-icons.svg#heart" />
    </svg>
    Watchlist
  </h3>

  <ng-container [ngTemplateOutlet]="fleetOverviewTemplate" [ngTemplateOutletContext]="{ areOwnAssets: false }"></ng-container>
</div>

<ng-template #fleetOverviewTemplate let-areOwnAssets="areOwnAssets">
  <ng-container *ngIf="!isViewGridActive">
    <ol *ngIf="isLoading" class="list-group">
      <li class="list-group-item">
        <p class="placeholder-glow m-0">
          <span style="width: 190px" class="placeholder float-end"></span>
          <span style="width: 120px" class="placeholder"></span>
          <br />
          <span style="width: 100px" class="placeholder"></span>
        </p>
      </li>
    </ol>

    <div *ngIf="!isLoading" class="list-group">
      <a
        [routerLink]="[asset.id]"
        *ngFor="let asset of (areOwnAssets ? ownedAssetsStatuses() : favoriteAssetsStatuses()) | orderBy : 'name'"
        class="list-group-item list-group-item-action list-group-item-light"
      >
        <div class="d-flex w-100 justify-content-between">
          <span>
            <span class="mb-1 fw-bolder fst-italic h5 me-1">{{ asset.name }}</span>
            <small>{{ asset.uuid }}</small>
          </span>
          <small *ngIf="areOwnAssets && asset.status !== undefined" class="text-body-secondary">
            <i
            [class.color-normal]="asset.status === DeviceStatusType.Normal"
            [class.color-warning]="asset.status === DeviceStatusType.Warn"
            [class.color-danger]="asset.status === DeviceStatusType.Error"
            class="status-icon-small fa-solid fa-circle"></i>
            Last updated: {{ !asset.lastUpdated ? "N/A" : asset.lastUpdated }}
          </small>
        </div>
      </a>
    </div>
  </ng-container>

  <div class="row" *ngIf="isViewGridActive">
    <div
      [routerLink]="[asset.id]"
      *ngFor="let asset of (areOwnAssets ? ownedAssetsStatuses() : favoriteAssetsStatuses()) | orderBy : 'name'"
      class="assets-grid-item col-sm-12 col-md-4 col-lg-3"
    >
      <div style="cursor: pointer" class="card h-100">
        <img *ngIf="asset.type === AssetTypeDto.VESSEL" height="200" src="images/vessel1.png" class="card-img-top" alt="" />
        <img *ngIf="asset.type === AssetTypeDto.STATIONARY" height="200" src="images/stationary.png" class="card-img-top" alt="" />
        <img *ngIf="asset.type === AssetTypeDto.VEHICLE" height="200" src="images/vehicle.png" class="card-img-top" alt="" />
        <div class="card-img-overlay">
          <h5 class="card-title text-white fw-bolder fst-italic">
            {{ asset.name }}
          </h5>
        </div>
        <div *ngIf="areOwnAssets" class="card-footer">
          <small class="text-body-secondary">
            <i
            [class.color-normal]="asset.status === DeviceStatusType.Normal"
            [class.color-warning]="asset.status === DeviceStatusType.Warn"
            [class.color-danger]="asset.status === DeviceStatusType.Error"
            class="status-icon-small fa-solid fa-circle"></i>

            Last updated: {{ !asset.lastUpdated ? "N/A" : asset.lastUpdated }}
          </small>
        </div>
      </div>
    </div>
  </div>
</ng-template>
