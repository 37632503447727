import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../services/shared-service';
import moment from 'moment';
import { FleetManagerComponents } from '../../shared/fleetmanager-components.module';
import { ActivatedRoute } from '@angular/router';
import { finalize, tap } from 'rxjs';
import { VesselClient } from '@dotocean/calypso-ngx-services';
import { ThemeService } from '../../services/theme-service';
import { AssetsAddComponent } from './assets-add/assets-add.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetListExtended, fromAssetList } from '../../models/asset-list-extended';
import { AssetClient, AssetTypeDto, ModuleTypeDto } from '../../services/apis/cloud.service';
import { ComponentHeaderComponent } from '../shared/component-header/component-header.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { validate as isValidUUID } from 'uuid';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';
import { ModuleExtended } from '../../models/module-extended';

@UntilDestroy()
@Component({
  selector: 'app-asset-list',
  standalone: true,
  imports: [FleetManagerComponents, AssetsAddComponent, ComponentHeaderComponent],
  templateUrl: './assets.component.html',
  styleUrl: './assets.component.scss',
})
export class AssetListComponent implements OnInit {
  public type: AssetTypeDto | undefined;
  public assets: AssetListExtended[] = [];
  public ownedAssets: AssetListExtended[] = [];
  public favoriteAssets: AssetListExtended[] = [];
  public isLoading = false;
  public query = '';

  public activeTheme = 'light';

  public AssetTypeDto = AssetTypeDto;

  public isViewGridActive = true;

  constructor(
    private readonly assetsClient: AssetClient,
    private readonly vesselClient: VesselClient,
    private readonly notificationService: NotificationService,
    private readonly route: ActivatedRoute,
    public readonly themeService: ThemeService,
    private readonly modalService: NgbModal,
    private readonly authService: AtlantisNgxAuthService
  ) {
    themeService.activeTheme$.subscribe((theme) => {
      this.activeTheme = theme;
    });
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.type = data['type'];

      this.loadAssets();
      this.notificationService.assetsChangedEvent$
        .pipe(
          untilDestroyed(this),
          tap(() => this.loadAssets())
        )
        .subscribe();
    });
  }

  public loadAssets() {
    this.isLoading = true;

    this.assetsClient
      .getAssets(this.type)
      .pipe(
        tap((result) => {
          this.assets = result.map((r) => fromAssetList(r));

          this.setAssetsVesselInfo();

          this.ownedAssets = this.assets.filter((e) => e.owned);
          this.favoriteAssets = this.assets.filter((e) => !e.owned);
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  private setAssetsVesselInfo() {
    for (const asset of this.assets) {
      if (asset.modules) {
        //TODO future: This asset.Online and asset.Lastupdated should be filled in in the API! --> The API will need to connect to AYB and get its values
        for (const module of asset.modules) {
          this.setAssetsVesselInfoAyb(asset, module);
        }
      }
    }
  }

  private setAssetsVesselInfoAyb(asset: AssetListExtended, module: ModuleExtended) {
    if (module.type !== ModuleTypeDto.AYB || !this.authService.HasApplicationAccess('AYB')) return;

    if (asset.owned && module.parsedConfig?.guid && isValidUUID(module.parsedConfig.guid)) {
      this.vesselClient
        .getVessel(module.parsedConfig.guid)
        .pipe(
          tap((info) => {
            asset.online = info.online ?? false;

            if (asset.online) asset.lastUpdated = 'Online';
            else asset.lastUpdated = moment.utc(info.online_changed).fromNow();
          })
        )
        .subscribe();
    }
  }

  public filterAssets(ev: any): void {
    this.query = ev.toLocaleLowerCase();
    this.ownedAssets = this.assets.filter((e) => (e.name ?? '').toLocaleLowerCase().indexOf(this.query) > -1 && e.owned);
    this.favoriteAssets = this.assets.filter((e) => (e.name ?? '').toLocaleLowerCase().indexOf(this.query) > -1 && !e.owned);
  }

  public openModal(): void {
    const modalRef = this.modalService.open(AssetsAddComponent);
    modalRef.componentInstance.fixedAssetType = this.type;

    modalRef.closed.subscribe((result: boolean) => {
      if (result) {
        this.loadAssets();
      }
    });
  }
}
