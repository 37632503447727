import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private eventSource = new Subject<string>();
  showToastEvent = this.eventSource.asObservable();

  showToast(message: string) {
    this.eventSource.next(message);
  }
}
