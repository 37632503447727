import { Injectable, OnDestroy } from '@angular/core';
import L, { ImageOverlay, LatLngBounds } from 'leaflet';
import { MapService } from './map.service';
import { filter, map } from 'rxjs';
import { ModuleExtended } from '../models/module-extended';
import { RadarWebSocketService } from './radar-websocket-service';
import { AssetClient, ModuleTypeDto } from './apis/cloud.service';
import { fromAsset } from '../models/asset-extended';
import { RadarImage } from '../models/radar-image';

@Injectable()
export class MapRadarService implements OnDestroy {
  private imageOverlay: ImageOverlay | undefined;
  private currentRadarModule: ModuleExtended | undefined;

  private centerRadarImageToMap = false;

  constructor(
    private readonly mapService: MapService,
    private readonly radarSocketService: RadarWebSocketService,
    private readonly assetsClient: AssetClient
  ) {}

  public getRadarModule$(vesselId: number) {
    return this.assetsClient.getAsset(vesselId).pipe(
      filter((r) => !!r.modules && r.modules.length > 0),
      map((response) => fromAsset(response)),
      map((response) => {
        for (const module of response.modules) {
          if (module.type == ModuleTypeDto.RADAR) {
            return module;
          }
        }
        return undefined;
      })
    );
  }

  public addRadarForVessel(radarModule: ModuleExtended, centerRadarImageToMap: boolean) {
    this.centerRadarImageToMap = centerRadarImageToMap;
    if (this.currentRadarModule) {
      this.removeRadar();
    }

    this.currentRadarModule = radarModule;
    this.connectToRadar(radarModule);
  }

  private connectToRadar(module: ModuleExtended) {
    module.radarSocket = this.radarSocketService.createRadarSocket(module.parsedConfig.guid);
    module.radarSocket.ImageChanged.subscribe((data) => {
      this.setImage(data);
    });
    module.radarSocket.connect();
  }

  private setImage(radarImage: RadarImage) {
    if (!this.mapService.Map || !this.currentRadarModule) return;
    this.removeImageOverlay();
    this.currentRadarModule.radarImage = radarImage.img;
    const bounds = this.calculateBounds(radarImage);
    this.imageOverlay = L.imageOverlay(radarImage.img, bounds).addTo(this.mapService.Map);
    if (this.centerRadarImageToMap) {
      let zoom = this.mapService.Map.getZoom();
      if (zoom > 15) zoom = 15;
      this.mapService.Map?.setView([radarImage.lat, radarImage.lng], zoom, { animate: true });

      this.centerRadarImageToMap = false;
    }
  }

  private calculateBounds(radarImage: RadarImage) {
    const lat = radarImage.lat;
    const lon = radarImage.lng;
    const radius = 516;
    let latRadians = lat * (Math.PI / 180);
    if (latRadians < 0) {
      latRadians += 2 * Math.PI;
    }
    const deltaLat = radius / 111319.444;
    const deltaLon = radius / (111319.444 * Math.cos(latRadians));
    const southWestLat = lat - deltaLat;
    const northEastLat = lat + deltaLat;
    const southWestLon = lon - deltaLon;
    const northEastLon = lon + deltaLon;
    return new LatLngBounds([southWestLat, southWestLon], [northEastLat, northEastLon]);
  }

  public removeRadar() {
    if (this.currentRadarModule?.radarSocket) {
      this.currentRadarModule.radarSocket.disconnect();
      this.currentRadarModule.radarSocket = undefined;
    }
    this.removeImageOverlay();
  }

  private removeImageOverlay() {
    if (this.imageOverlay) {
      this.mapService.removeLayer(this.imageOverlay);
      this.imageOverlay = undefined;
    }
  }

  public ngOnDestroy(): void {
    this.removeRadar();
  }
}
