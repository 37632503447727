import { Component, effect, EventEmitter, inject, OnDestroy, Output } from '@angular/core';
import { MapItemPopupComponent } from '../../shared/map-item-popup/map-item-popup.component';
import { MapTrackerData } from '../../../models/map-tracker-data';
import { FleetManagerComponents } from '../../../shared/fleetmanager-components.module';
import { MapService } from '../../../services/map.service';
import { ModuleTypeDto } from '../../../services/apis/cloud.service';
import { MapRadarService } from '../../../services/map-radar.service';
import { fromModule, ModuleExtended } from '../../../models/module-extended';
import { MapHistoryService } from '../services/map-replay-history.service';
import moment from 'moment';
import { ObjectsCacheApiService } from '../../../services/objects-cache-api-service';
import { NgxColorsModule } from 'ngx-colors';
import { ObjectClient, SourceTypeColor } from '@dotocean/virtualworld-ngx-services';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';
import { tap } from 'rxjs';
import { ToastService } from '../../../services/toast-service';
import { MapMarkerService } from '../../../services/map-marker.service';
import { MapOptionsService } from '../services/map-options.service';
import { MapOptionsFollowService } from '../services/map-options-follow.service';

@Component({
  selector: 'app-map-object',
  standalone: true,
  imports: [MapItemPopupComponent, FleetManagerComponents, NgxColorsModule],
  providers: [MapRadarService],
  templateUrl: './map-object.component.html',
  styleUrl: './map-object.component.scss',
})
export class MapObjectComponent implements OnDestroy {
  private readonly mapService = inject(MapService);
  private readonly radarMapService = inject(MapRadarService);
  public readonly mapHistoryService = inject(MapHistoryService);
  public readonly mapMarkerService = inject(MapMarkerService);
  public readonly mapOptionsService = inject(MapOptionsService);
  public readonly mapOptionsFollowService = inject(MapOptionsFollowService);

  public readonly objectClient = inject(ObjectClient);

  public readonly objectsCacheApiService = inject(ObjectsCacheApiService);
  public readonly authService = inject(AtlantisNgxAuthService);
  private readonly toastService = inject(ToastService);

  public selectedMapTrackerData: MapTrackerData | undefined;

  public assetIconHovering = false;

  //TODO: Radar avaialability should come from MapTrackerData?? We should store Asset info here maybe?
  public radarAvailable = false;
  public radarActive = false;
  public radarModule?: ModuleExtended;

  private timer: NodeJS.Timeout;
  public timeDifference = '';
  public timeDifferenceInMinutes: number;

  @Output() public colorChanged = new EventEmitter<SourceTypeColor>();

  constructor() {
    effect(() => {
      this.selectedMapTrackerData = this.mapMarkerService.selectedMapTrackerData();

      this.destroyTimer();

      this.calculateTimeDifference();
      //Update the time difference every 10 seconds
      this.timer = setInterval(() => {
        this.calculateTimeDifference();
      }, 10000); // 10 seconds

      this.radarAvailable = false;
      this.clearRadar();

      const radarModule = this.getRadarModule();
      this.radarAvailable = radarModule !== undefined;
    });
  }

  public sourceTypeColorChanged(newColor: string) {
    if (!this.selectedMapTrackerData) return;

    const stc = { ...this.selectedMapTrackerData.sourceType, color: newColor } as SourceTypeColor;
    return this.objectClient
      .upsertTypesColors(stc.groupId!, undefined, stc)
      .pipe(
        tap(() => {
          this.colorChanged.emit(stc);
          this.toastService.showToast('Color for AIS ship type: ' + stc.shipType + ' has been updated.');
        })
      )
      .subscribe();
  }

  private destroyTimer() {
    if (this.timer) clearInterval(this.timer);
  }

  public ngOnDestroy(): void {
    this.destroyTimer();
  }
  public calculateTimeDifference() {
    if (!this.selectedMapTrackerData) {
      this.destroyTimer();

      return;
    }

    const now = moment();
    //Doing this becuase SelectedMaptrackerData might nog the be same as the original one from the 'Click' Because each time an object is updated (Through the Websocket) --> A new MapTracker class is created.
    const timeFromObject = this.selectedMapTrackerData.obj
      ? moment.unix(this.selectedMapTrackerData.obj.time! / 1000).local()
      : this.selectedMapTrackerData.time;
    const lastUpdateMoment = moment(timeFromObject);

    console.log('mmsi:' + this.selectedMapTrackerData.mmsi + ' lastUpdateMoment', timeFromObject.toString());

    this.timeDifference = lastUpdateMoment.fromNow(); // e.g., "a few seconds ago"
    this.timeDifferenceInMinutes = now.diff(lastUpdateMoment, 'minutes');
  }

  private getRadarModule() {
    if (this.selectedMapTrackerData?.asset?.modules) {
      for (const module of this.selectedMapTrackerData.asset.modules) {
        if (module.type == ModuleTypeDto.RADAR) {
          return module;
        }
      }
    }

    return undefined;
  }

  private clearRadar() {
    this.radarActive = false;

    if (this.radarModule) {
      this.radarMapService.removeRadar();
    }
  }

  public centerMapToTracker() {
    this.mapService.centerViewToCoordinate([this.selectedMapTrackerData!.lat, this.selectedMapTrackerData!.lng]);
  }

  public toggleRadarInfo() {
    if (this.radarActive) {
      this.clearRadar();
      return;
    }

    const radarModule = this.getRadarModule();
    if (radarModule) {
      this.radarModule = fromModule(radarModule);
      this.radarMapService.addRadarForVessel(this.radarModule, false);
      this.radarActive = true;
    }
  }

  public showNavigationStatus(navigationStatus: number | undefined) {
    if (navigationStatus === undefined) return 'Unknown';
    return this.mapOptionsService.navStatusSignal().find((status) => status.code === navigationStatus)?.value ?? 'Unknown';
  }
}
