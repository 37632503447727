import { Component, effect, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MapService } from '../../../services/map.service';
import { ThemeService } from '../../../services/theme-service';

@Component({
  selector: 'app-map-item-popup',
  standalone: true,
  imports: [],
  templateUrl: './map-item-popup.component.html',
  styleUrl: './map-item-popup.component.scss',
})
export class MapItemPopupComponent implements OnInit, OnDestroy {
  private _coordinates: [number, number] | undefined;
  public get coordinates(): [number, number] | undefined {
    return this._coordinates;
  }
  @Input({ required: true })
  public set coordinates(coordinates: [number, number] | undefined) {
    this._coordinates = coordinates;
    this.updatePopupLocationOnMap();
  }

  @Input() public unknownVesselLocation = false;

  public left = 0;
  public top = 0;

  private readonly mapService = inject(MapService);
  private readonly themeService = inject(ThemeService);

  constructor() {
    effect(() => {
      if (this.mapService.mapLoaded()) {
        this.mapService.Map!.on('move', this.updatePopupLocationOnMap.bind(this));
        this.mapService.Map!.on('zoom', this.updatePopupLocationOnMap.bind(this));
      }
    });
  }

  public ngOnInit(): void {
    this.updatePopupLocationOnMap();
  }

  private updatePopupLocationOnMap() {
    // if(this.unknownVesselLocation){
    //   this.left = ; // CENTER screen
    //   this.top = 0; // CENTER screen
    //   return;
    // }
    if (!this.mapService.Map || !this._coordinates) return;
    const point = this.mapService.Map.latLngToContainerPoint(this._coordinates);

    // Update the position of the component
    this.left = point.x - 20; //10 is the padding offset
    this.top = point.y;
  }

  public ngOnDestroy() {
    // Clean up map event listeners
    if (this.mapService.Map) {
      this.mapService.Map.off('move', this.updatePopupLocationOnMap.bind(this));
      this.mapService.Map.off('zoom', this.updatePopupLocationOnMap.bind(this));
    }
  }
}
