import { Module } from '../services/apis/cloud.service';
import { RadarWebSocket } from '../services/radar-websocket-service';
import { WebRTCConnection } from './webRTCConnection';

export interface ModuleExtended extends Module {
  parsedConfig?: any;
  selectedConfigFile?: any;
  configFiles?: ConfigFile[];

  events?: any[];
  shownEvents?: any[];
  selectedEventPage: number;

  webrtcConnection?: WebRTCConnection;
  webRtcGuid?: string;
  radarSocket?: RadarWebSocket;
  radarImage?: string;

  guid?: string;
}

export class ConfigFile {
  public name: string;
  public content: string;
}

export function fromModule(asset: Module) {
  const result = {} as ModuleExtended;
  Object.assign(result, asset);

  setModuleExtendedProps(result);
  return result;
}

export function setModuleExtendedProps(module: ModuleExtended) {
  module.selectedEventPage = 1;
  if (module.config != null) module.parsedConfig = JSON.parse(module.config);
  else {
    module.parsedConfig = { subModules: { core: true } };
    module.config = JSON.stringify(module.parsedConfig);
  }

  module.selectedConfigFile = { content: '' };
}
