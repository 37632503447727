import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private eventSource = new Subject<void>();
  public assetsChangedEvent$ = this.eventSource.asObservable();

  assetsChanged() {
    this.eventSource.next();
  }
}
