import { InjectionToken } from '@angular/core';
import { IMqttServiceOptions } from 'ngx-mqtt';
import { IRtcServiceOptions } from './services/webrtc-service';

export interface AppConfig {
  endpoints: {
    api: string;
    ayb: string;
    objects: string;
    config: string;
    camera: string;
    radar: string;
    nvr: string;
    monitor: string;
    datawarehouse: string;
  };
  mqtt: IMqttServiceOptions;
  rtc: IRtcServiceOptions;
}

export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');
