import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from '../../../services/shared-service';
import { ToastService } from '../../../services/toast-service';
import { ThemeService } from '../../../services/theme-service';
import { FleetManagerComponents } from '../../../shared/fleetmanager-components.module';
import { CerebroApiService } from '../../../services/cerebro-api-service';
import { AssetClient, Asset, AssetTypeDto, ResData, Group } from '../../../services/apis/cloud.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VesselSearchComponent } from '../../shared/vessel-search/vessel-search.component';

@Component({
  selector: 'app-assets-add',
  standalone: true,
  imports: [FleetManagerComponents, VesselSearchComponent],
  templateUrl: './assets-add.component.html',
  styleUrl: './assets-add.component.scss',
})
export class AssetsAddComponent implements OnInit {
  @Input()
  public fixedAssetType: AssetTypeDto | undefined;

  public asset: Asset;

  public AssetTypeDto = AssetTypeDto;
  public groups: Group[] = [];

  public activeTheme = 'light';
  public selectedVessel: ResData | undefined;

  constructor(
    private readonly assetClient: AssetClient,
    private readonly cerebroService: CerebroApiService,
    private readonly notificationService: NotificationService,
    private readonly toastService: ToastService,
    public readonly themeService: ThemeService,
    public readonly activeModal: NgbActiveModal
  ) {
    themeService.activeTheme$.subscribe((theme) => {
      this.activeTheme = theme;
    });
  }

  public ngOnInit(): void {
    this.cerebroService.groups$.subscribe((groups) => {
      this.groups = groups;
      this.reset();
    });
  }

  public selectType(type: AssetTypeDto) {
    this.asset.type = type;
  }

  private reset() {
    this.selectedVessel = undefined;

    this.asset = { owned: true, type: this.fixedAssetType ?? AssetTypeDto.VESSEL };

    if (this.groups.length > 0) this.asset.groupId = this.groups[0].id;
  }

  public async addAsset() {
    if (!this.asset) return;
    this.assetClient.create(this.asset).subscribe(() => {
      this.notificationService.assetsChanged();
      this.toastService.showToast('Asset has been added.');

      this.activeModal.close(true);
    });
  }

  public selectedItem(item: ResData | undefined) {
    if (!item) return;
    this.asset.name = item.name;
    this.asset.uuid = item.id ? +item.id : 0;
  }
}
