<div style="height: calc(100vh - 49px);" id="map">
    <div class="markerLegend">
        <div>
            <svg style="fill: #78D0E7;" width="20" height="20">
                <use href="bootstrap-icons.svg#circle-fill" />
            </svg>
            <span class="ms-1">AIS</span>
        </div>
        <div class="mt-1 mb-1">
            <svg style="fill: #E1234A;" width="20" height="20">
                <use href="bootstrap-icons.svg#circle-fill" />
            </svg>
            <span class="ms-1">Radar</span>
        </div>
        <div>
            <svg style="fill: #EC8E2F;" width="20" height="20">
                <use href="bootstrap-icons.svg#circle-fill" />
            </svg>
            <span class="ms-1">AYB (GPS)</span>
        </div>

    </div>
    <div class="legend">
        <div>
            <img width="40" src="icons/vessel-owned.svg" alt="" />
            My fleet
        </div>
        <div style="margin-top: 5px;">
            <img width="40" src="icons/vessel-favorite.svg" alt="" />
            Watchlist
        </div>
    </div>
</div>