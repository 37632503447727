import { Component, inject, Input } from '@angular/core';
import { FleetManagerComponents } from '../../../../shared/fleetmanager-components.module';
import { AssetExtended } from '../../../../models/asset-extended';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../services/toast-service';
import { AssetClient, Group } from '../../../../services/apis/cloud.service';
import { finalize, tap } from 'rxjs';
import { CerebroApiService } from '../../../../services/cerebro-api-service';
import { Router } from '@angular/router';
import { NotificationService } from '../../../../services/shared-service';
import { ThemeService } from '../../../../services/theme-service';

@Component({
  selector: 'app-assets-detail-edit',
  standalone: true,
  imports: [FleetManagerComponents],
  templateUrl: './assets-detail-edit.component.html',
  styleUrl: './assets-detail-edit.component.scss',
})
export class AssetsDetailEditComponent {
  @Input({ required: true }) public editAsset: AssetExtended;

  public isSaving = false;
  public groups: Group[] = [];
  public showConfirm = false;

  public readonly activeModal = inject(NgbActiveModal);
  private readonly toastService = inject(ToastService);
  private readonly cerebroService = inject(CerebroApiService);
  private readonly assetClient = inject(AssetClient);
  private readonly router = inject(Router);
  private readonly notificationService = inject(NotificationService);
  public readonly themeService = inject(ThemeService);

  constructor() {
    this.cerebroService.groups$.pipe(tap((groups) => (this.groups = groups))).subscribe();
  }

  public updateAsset() {
    this.isSaving = true;

    const patchOperations = [
      { op: 'replace', path: '/name', value: this.editAsset.name },
      { op: 'replace', path: '/uuid', value: this.editAsset.uuid },
      { op: 'replace', path: '/groupid', value: this.editAsset.groupId },
      { op: 'replace', path: '/owned', value: this.editAsset.owned },
      { op: 'replace', path: '/imageUrl', value: this.editAsset.imageUrl },
    ];

    this.assetClient
      .update(this.editAsset.id!, patchOperations)
      .pipe(
        tap(() => {
          this.toastService.showToast('Asset has been updated.');
          this.activeModal.close(true);
        }),
        finalize(() => (this.isSaving = false))
      )
      .subscribe();
  }

  public removeAsset() {
    this.isSaving = true;
    this.showConfirm = false;

    this.assetClient
      .delete(this.editAsset.id!)
      .pipe(
        tap(() => {
          this.notificationService.assetsChanged();
          this.toastService.showToast('Asset has been removed.');
          this.activeModal.close(true);

          let url = this.editAsset?.type?.toLocaleLowerCase();
          if (url !== 'stationary') url += 's';

          this.router.navigate([url]);
        }),
        finalize(() => (this.isSaving = false))
      )
      .subscribe();
  }
}
