import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AtlantisNgxAuthGuard, AtlantisNgxAuthInterceptor, AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';
import { ConfigApiService } from '../services/config-api-service';
import { CameraApiService } from '../services/camera-api-service';
import { ObjectsWebSocketService } from '../services/objects-websocket-service';
import { NVRApiService } from '../services/nvr-service';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { MqttService } from '../services/mqtt-service';
import { RadarWebSocketService } from '../services/radar-websocket-service';
import { VIRTUALWORLD_API_BASE_URL, VirtualworldNgxObjectsSocketService } from '@dotocean/virtualworld-ngx-services';
import { CALYPSO_API_BASE_URL } from '@dotocean/calypso-ngx-services';
import { ToastService } from '../services/toast-service';
import { FleetManagerWebSocketService } from '../services/fleetmanager-websocket.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CerebroApiService } from '../services/cerebro-api-service';
import { ThemeService } from '../services/theme-service';
import { APP_CONFIG, AppConfig } from '../app.config';
import { AppconfigService } from '../services/appconfig.service';
import { CLOUD_API_BASE_URL } from '../services/apis/cloud.service';
import { provideDaterangepickerLocale } from "ngx-daterangepicker-bootstrap";
import { FleetmanagerApiService } from '../services/fleetmanager-api';
import { provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { MapPopupService } from '../services/map-popup.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ObjectsCacheApiService } from '../services/objects-cache-api-service';

const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'mqtt.dotocean.io',
  port: 443,
  protocol: 'wss',
  username: 'admin_user',
  password: 'GqMvLjMJVRXyZJmemylstp11',
  keepalive: 10,
  connectOnCreate: false,
};

export function appInitializer(appconfigService: AppconfigService) {
  return async () => {
    await Promise.all([appconfigService.loadConfig()]);
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_CONFIG,
      useFactory: (appconfigService: AppconfigService) => appconfigService.getConfig(),
      deps: [AppconfigService],
    },
    provideLottieOptions({ player: () => player }),
    provideCacheableAnimationLoader(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    AtlantisNgxAuthService,
    AtlantisNgxAuthGuard,
    ConfigApiService,
    CameraApiService,
    CerebroApiService,
    ObjectsCacheApiService,
    FleetmanagerApiService,
    ObjectsWebSocketService,
    FleetManagerWebSocketService,
    NVRApiService,
    RadarWebSocketService,
    VirtualworldNgxObjectsSocketService,
    ThemeService,
    MapPopupService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AtlantisNgxAuthInterceptor,
      multi: true,
    },
    {
      provide: CALYPSO_API_BASE_URL,
      useFactory: (config: AppConfig) => config.endpoints.ayb,
      deps: [APP_CONFIG],
    },
    {
      provide: VIRTUALWORLD_API_BASE_URL,
      useFactory: (config: AppConfig) => config.endpoints.objects,
      deps: [APP_CONFIG],
    },
    { provide: CLOUD_API_BASE_URL, useFactory: (config: AppConfig) => config.endpoints.api, deps: [APP_CONFIG] },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AppconfigService],
    },
    importProvidersFrom(MqttModule.forRoot(MQTT_SERVICE_OPTIONS)),
    MqttService,
    ToastService,
    provideDaterangepickerLocale({
      separator: ' - ',
      applyLabel: 'Okay',
    }),
  ],
};
