import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FleetManagerComponents } from '../../../../../shared/fleetmanager-components.module';
import { ModuleExtended } from '../../../../../models/module-extended';
import { ModuleTypeDto } from '../../../../../services/apis/cloud.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { ThemeService } from '../../../../../services/theme-service';

@UntilDestroy()
@Component({
  selector: 'app-assets-detail-modules-typeditem',
  standalone: true,
  imports: [FleetManagerComponents],
  templateUrl: './assets-detail-modules-typeditem.component.html',
  styleUrl: './assets-detail-modules-typeditem.component.scss',
})
export class AssetsDetailModulesTypeditemComponent {
  @Input({ required: true }) public title: string;
  @Input({ required: true }) public type: ModuleTypeDto;
  @Input({ required: true }) public modules: ModuleExtended[];
  @Input({ required: true }) selectedModule?: ModuleExtended;
  @Input({ required: true }) isCollapsed = false;

  @Output()
  public editModule = new EventEmitter<ModuleExtended>();
  @Output()
  public selectModule = new EventEmitter<ModuleExtended>();

  public ModuleTypeDto = ModuleTypeDto;

  public currentTheme = 'light';

  constructor(themeService: ThemeService) {
    themeService.activeTheme$
      .pipe(
        untilDestroyed(this),
        tap((theme) => (this.currentTheme = theme))
      )
      .subscribe();
  }

  public getModuleTypeImage(currentTheme: string, isSelected: boolean) {
    switch (this.type) {
      case ModuleTypeDto.AYB:
        return currentTheme === 'light' || isSelected ? 'icons/joystick-ayb-dark.svg' : 'icons/joystick-ayb-white.svg';
      case ModuleTypeDto.CAMERA:
        return currentTheme === 'light' || isSelected ? 'icons/visor-dark.svg' : 'icons/visor-white.svg';
      case ModuleTypeDto.RADAR:
        return currentTheme === 'light' || isSelected ? 'icons/radar-dark.svg' : 'icons/radar-white.svg';
    }
  }
}
