<div class="map-history">
  @if(historyDataLoaded){

  <div class="map-history--content">
    <div class="map-history--content-filter">
      <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t" outsideDays="hidden" />
      <div class="text-center">
        @if(aisReplayService.vessels()){ @for (vessel of aisReplayService.vessels(); track $index) {
        <span (click)="removeVesselFromReplay(vessel)" class="badge text-bg-primary">
          {{ vessel.vesselInfo.name }} ({{ vessel.vesselInfo.mmsi }})
        </span>
        } }
        <!-- <span class="badge text-bg-success">
            <i class="fa-solid fa-plus"></i>
          </span> -->
        <app-events-detail-player [showSlider]="false"></app-events-detail-player>
      </div>
    </div>

    @if (aisReplayService.vessels().length > 0 && minimumHistoryDate) {
    <app-events-detail-echart class="map-history--content-controller" [minimumHistoryDate]="minimumHistoryDate" [isBiggerChart]="true"></app-events-detail-echart>
    }
  </div>

  }@else {
  <app-loading-list [isLoading]="!historyDataLoaded"></app-loading-list>
  }

  <div class="map-history--close prevent-select" (click)="mapHistoryService.cancelReplayMode()">
    <i class="map-history--close-icon fa-solid fa-close" (click)="mapHistoryService.cancelReplayMode()"></i>
    Close replay
  </div>
</div>

<ng-template #t let-date let-focused="focused" let-currentMonth="currentMonth">
  <span
    class="custom-day"
    [class.outside-day]="date.month !== currentMonth"
    [class.focused]="focused"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>
