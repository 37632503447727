import { Component, effect, inject } from '@angular/core';
import { MapService } from '../../../services/map.service';
import { ThemeService } from '../../../services/theme-service';
import { FleetManagerComponents } from '../../../shared/fleetmanager-components.module';
import { AisReplayService } from '../../../services/ais-replay.service';
import { EventsDetailPlayerComponent } from '../../events/events-detail/events-detail-player/events-detail-player.component';
import { VesselToFollow } from '../../events/events-detail/vessel-tofollow.class';
import { AggregateLevel, GetObjectHistoryResponse, GetObjectsResponse, HistoryClient } from '@dotocean/virtualworld-ngx-services';
import { MapHistoryService } from '../services/map-replay-history.service';
import moment from 'moment';
import { tap, catchError, of, zip, map } from 'rxjs';
import { deduceHistoryDateRange } from '../../../helpers/object.history.helper';
import { VesselInfo } from '../../../services/apis/cloud.service';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { EventsDetailEchartComponent } from '../../events/events-detail/events-detail-echart/events-detail-echart.component';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';

@Component({
  selector: 'app-map-history',
  standalone: true,
  imports: [FleetManagerComponents, EventsDetailPlayerComponent, NgbDatepickerModule, EventsDetailEchartComponent],
  providers: [AisReplayService],
  templateUrl: './map-history.component.html',
  styleUrl: './map-history.component.scss',
})
export class MapHistoryComponent {
  private readonly mapService = inject(MapService);
  public readonly mapHistoryService = inject(MapHistoryService);
  private readonly historyClient = inject(HistoryClient);
  public readonly aisReplayService = inject(AisReplayService);
  private readonly themeService = inject(ThemeService);
  public readonly calendar = inject(NgbCalendar);
  public readonly formatter = inject(NgbDateParserFormatter);
  private readonly authService = inject(AtlantisNgxAuthService);

  public enableReplay = false;

  public from: NgbDate | null;
  public until: NgbDate | null;
  public hoveredDate: NgbDate | null;
  public minimumHistoryDate?: moment.Moment;

  public historyDataLoaded = false;

  private resetDates(reload = true) {
    const today = this.calendar.getToday();
    this.until = this.calendar.getNext(today, 'd', 1);
    this.from = today;

    if (reload) this.reloadData(this.mapHistoryService.objectsForReplay());
  }

  private reloadData(objList: GetObjectsResponse[]) {
    if (objList.length <= 0) return;

    let vessels: VesselToFollow[] = [];

    this.historyDataLoaded = false;
    const vesselHistoryData$ = objList.map((v, index) => {
      this.minimumHistoryDate = !this.from ? moment.utc() : this.formatDate(this.from);
      const from = this.minimumHistoryDate.startOf('day').toDate();
      if (!this.until) this.until = this.calendar.getNext(this.from!, 'd', 1);
      const until = new Date(Date.UTC(this.until.year, this.until.month - 1, this.until.day));

      return this.historyClient.getHistory(from, until, AggregateLevel.SECOND_10, [v.oid!], undefined, undefined, undefined).pipe(
        map((histories: GetObjectHistoryResponse[]) => deduceHistoryDateRange(histories, moment.utc(until))),
        tap((histories) => {
          vessels.push(
            new VesselToFollow(
              { mmsi: v.oid, name: v.name, width: v.w, length: v.l, latitude: v.lat, longitude: v.lng } as VesselInfo,
              true,
              histories,
              from,
              until,
              this.themeService.greenColors.length - 1 >= index ? this.themeService.greenColors[index] : undefined
            )
          );
        }),
        catchError(() => of([]))
      );
    });

    zip(vesselHistoryData$ && vesselHistoryData$.length > 0 ? vesselHistoryData$ : [of([])])
      .pipe(
        tap(() => {
          vessels = vessels.sort((a, b) => {
            if (a.isTarget && !b.isTarget) {
              return -1;
            } else if (!a.isTarget && b.isTarget) {
              return 1;
            } else {
              return a.start.toDate().getTime() - b.start.toDate().getTime();
            }
          });

          this.aisReplayService.setVessels(vessels);
          this.historyDataLoaded = true;
        })
      )
      .subscribe();
  }

  constructor() {
    this.resetDates(false);

    effect(() => {
      const vessels = this.aisReplayService.vessels();
      if (vessels.length > 0) {
        this.mapService.fitVessels(vessels);
      }
    });

    effect(
      () => {
        this.reloadData(this.mapHistoryService.objectsForReplay());
      },
      { allowSignalWrites: true }
    );
  }

  public removeVesselFromReplay(vessel: VesselToFollow) {
    this.mapHistoryService.removeObjectFromReplay(vessel.vesselInfo.mmsi!);
  }

  public onDateSelection(date: NgbDate) {
    if (!this.from && !this.until) {
      this.from = date;
      this.until = this.calendar.getPrev(date, 'd', 1);
    } else if (this.from && !this.until && date.after(this.from)) {
      this.until = date;
      this.reloadData(this.mapHistoryService.objectsForReplay());
    } else {
      this.until = null;
      this.from = date;
    }
  }

  public validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  public isHovered(date: NgbDate) {
    return this.from && !this.until && this.hoveredDate && date.after(this.from) && date.before(this.hoveredDate);
  }

  public isInside(date: NgbDate) {
    return this.until && date.after(this.from) && date.before(this.until);
  }

  public isRange(date: NgbDate) {
    return date.equals(this.from) || (this.until && date.equals(this.until)) || this.isInside(date) || this.isHovered(date);
  }

  private formatDate(date: NgbDate) {
    // NgbDates use 1 for Jan, Moement uses 0, must substract 1 month for proper date conversion
    const ngbObj = JSON.parse(JSON.stringify(date));
    const newMoment = moment();

    if (ngbObj) {
      ngbObj.month--;
      newMoment.month(ngbObj.month);
      newMoment.dates(ngbObj.day);
      newMoment.year(ngbObj.year);
    }
    return newMoment;
  }

}
