import { InjectionToken } from "@angular/core";

export interface AppConfig {
  endpoints: {
    api: string;
    ayb: string;
    objects: string;
    config: string;
    camera: string;
    radar: string;
    nvr: string;
  };
}

export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG')
