import { Asset } from '../services/apis/cloud.service';
import { ModuleExtended, setModuleExtendedProps } from './module-extended';

export interface AssetExtended extends Asset {
  lastUpdated: string;
  online: boolean;

  modules: ModuleExtended[];
}

export function fromAsset(asset: Asset) {
  const result = {} as AssetExtended;
  Object.assign(result, asset);

  if (result.modules) {
    for (const module of result.modules) {
      setModuleExtendedProps(module);
    }
  }

  return result;
}
