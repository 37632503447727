import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { Group } from '../models/cerebro/group';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';

@Injectable()
export class CerebroApiService {
  public url = 'https://api.dotocean.eu/cerebro/';
  public groups$: Observable<Group[]>;

  constructor(private http: HttpClient, authService: AtlantisNgxAuthService) {

    this.groups$ = this.http.get<Group[]>(this.url + 'group').pipe(
      shareReplay(1),
      map((groups) => {
        const authorizedGroups = authService.identity?.Application.Groups ?? [];
        return groups.filter((group: any) => authorizedGroups?.indexOf(group.Id) > -1);
      }
      )
    );
  }
}