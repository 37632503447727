<div class="container-fluid">
  <app-component-header
    title="Alerts"
    newButtonText="New Alert"
    (addNewClicked)="openModal()"
    [enableSearching]="false"
    [enableViewToggling]="false"
  ></app-component-header>
  <!-- <div class="card mb-3 border-0 border-bottom rounded-0">
    <div class="card-body">
      <div class="row justify-content-between align-items-center flex-grow-1f">
        <div class="col-md">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="card-header-title">Alerts</h4>
          </div>
        </div>
        <div class="col-auto">
          <div class="row align-items-sm-center">
            <div class="col-auto">
              <button *ngIf="activeTheme === 'light'" type="button" class="btn btn-primary" (click)="openModal()">
                <svg style="vertical-align: -6px" class="bi pe-none" width="24" height="24">
                  <use href="bootstrap-icons.svg#plus" />
                </svg>
                New Alert
              </button>
              <button *ngIf="activeTheme === 'dark'" type="button" class="btn btn-light" (click)="openModal()">
                <svg style="vertical-align: -6px" class="bi pe-none" width="24" height="24">
                  <use href="bootstrap-icons.svg#plus" />
                </svg>
                New Alert
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <app-loading-list [isLoading]="isLoading"></app-loading-list>

  <ng-container *ngIf="!isLoading">
    <div *ngIf="alerts.length > 0; else noItems" class="col-12">
      <table class="table table-striped">
        <thead>
          <tr>
            <th style="width: 66px" scope="col"></th>
            <th scope="col">Created</th>
            <th scope="col">Name</th>
            <th scope="col">Source</th>
            <th scope="col">Type</th>
            <th style="width: 66px" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let alert of alerts | orderBy : 'created'">
            <th>
              <div class="form-check form-switch">
                <input [(ngModel)]="alert.enabled" class="form-check-input" type="checkbox" role="switch" (change)="updateAlert(alert)" />
              </div>
            </th>
            <td>
              {{ moment.utc(alert.created).local().format("DD/MM/YYYY HH:mm") }}
            </td>
            <td>{{ alert.name }}</td>
            <td>{{ alert.source ?? "" | firstLetterCapital }}</td>
            <td>{{ alert.type ?? "" | firstLetterCapital }}</td>
            <td>
              <div class="dropdown">
                <svg style="cursor: pointer" class="me-1 bi dropdown-toggle" width="15" height="15" data-bs-toggle="dropdown" aria-expanded="false">
                  <use href="bootstrap-icons.svg#three-dots-vertical" />
                </svg>
                <ul class="dropdown-menu">
                  <li>
                    <button type="button" class="dropdown-item text-danger" (click)="removeAlert(alert)">Remove</button>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <div class="modal fade" id="addAlertModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <form class="needs-validation" (ngSubmit)="addAlert()">
          <div class="modal-header">
            <span style="margin-top: 1px" class="me-3">{{ currentStep }}/3</span>
            <h1 class="modal-title fs-5" id="exampleModalLabel">New Alert</h1>
            <button (click)="closeModal()" type="button" class="btn-close" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div *ngIf="currentStep === 1" class="mb-3">
              <p class="fs-5">Setup the condition that triggers the alert</p>
              <label for="alert-name" class="col-form-label fw-semibold">Name</label>
              <input
                [(ngModel)]="alert.name"
                type="text"
                class="form-control"
                name="alert-name"
                id="alert-name"
                required=""
                placeholder="Name of alert"
              />

              <label class="col-form-label fw-semibold mt-2">Choose which assets can trigger alert</label>
              <br />
              <div class="btn-group" role="group">
                <input [(ngModel)]="alert.source" value="ALL" type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" />
                <label
                  [ngClass]="
                    alert.source === AlertSourceDto.ALL ? 'radioActive' : activeTheme === 'light' ? 'radioInactiveLight' : 'radioInactiveDark'
                  "
                  class="btn btn-outline-primary"
                  for="btnradio3"
                >
                  All
                </label>
                <input [(ngModel)]="alert.source" value="OWNED" type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" />
                <label
                  [ngClass]="
                    alert.source === AlertSourceDto.OWNED ? 'radioActive' : activeTheme === 'light' ? 'radioInactiveLight' : 'radioInactiveDark'
                  "
                  class="btn btn-outline-primary"
                  for="btnradio1"
                >
                  My fleet
                </label>
                <input [(ngModel)]="alert.source" value="FAVORITE" type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" />
                <label
                  [ngClass]="
                    alert.source === AlertSourceDto.FAVORITE ? 'radioActive' : activeTheme === 'light' ? 'radioInactiveLight' : 'radioInactiveDark'
                  "
                  class="btn btn-outline-primary"
                  for="btnradio2"
                >
                  Watchlist
                </label>
              </div>
            </div>
            <div [style.display]="currentStep === 2 ? 'block' : 'none'">
              <p class="fs-5">Setup the condition that triggers the alert</p>
              <label class="col-form-label fw-semibold">GeoJSON area for boundary crossing</label>
              <br />
              <input #inputFile class="form-control mb-2" type="file" id="formFile" (change)="onFileChange($event)" accept=".geojson" />
              <div id="alertMap" style="height: 200px"></div>
            </div>
            <div *ngIf="currentStep === 3">
              <p class="fs-5">Which type of notification would you like to receive?</p>
              <input
                [(ngModel)]="alert.type"
                [value]="AlertTypeDto.IN_APP"
                type="radio"
                class="btn-check"
                name="btnradio"
                id="btnradio"
                autocomplete="off"
              />
              <label
                [ngClass]="alert.type === AlertTypeDto.IN_APP ? 'radioActive' : activeTheme === 'light' ? 'radioInactiveLight' : 'radioInactiveDark'"
                class="btn btn-outline-primary"
                for="btnradio"
              >
                In app
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button *ngIf="currentStep === 2 || currentStep === 3" (click)="back()" type="button" class="btn btn-outline-secondary">Back</button>
            <button
              *ngIf="(currentStep === 1 || currentStep === 2) && activeTheme === 'light'"
              (click)="continue()"
              type="button"
              class="btn btn-primary"
            >
              Continue
            </button>
            <button
              *ngIf="(currentStep === 1 || currentStep === 2) && activeTheme === 'dark'"
              (click)="continue()"
              type="button"
              class="btn btn-light"
            >
              Continue
            </button>
            <button *ngIf="currentStep === 3 && activeTheme === 'light'" type="submit" class="btn btn-primary">Save</button>
            <button *ngIf="currentStep === 3 && activeTheme === 'dark'" type="submit" class="btn btn-light">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #noItems>
  <app-empty-list title="There are currently no alerts" addButtonText="New alert" (add)="openModal()"></app-empty-list>
</ng-template>
