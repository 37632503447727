import { Component, Input } from '@angular/core';
import { FleetManagerComponents } from '../../shared/fleetmanager-components.module';
import { CerebroApiService } from '../../services/cerebro-api-service';
import { NotificationService } from '../../services/shared-service';
import { ToastService } from '../../services/toast-service';
import { ThemeService } from '../../services/theme-service';
import { AssetsAddComponent } from '../assets/assets-add/assets-add.component';
import { SidebarItemComponent } from './sidebar-item/sidebar-item.component';
import { Group } from '../../models/cerebro/group';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardClient, Dashboard, EventTypeDto } from '../../services/apis/cloud.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [FleetManagerComponents, AssetsAddComponent, SidebarItemComponent],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  @Input() selectedLink!: string;

  public groups: Group[] = [];
  public isCollapsed = false;
  public totalObjects: Dashboard = {};

  public eventTypeDto = EventTypeDto;

  public activeTheme = 'light';

  constructor(
    private readonly dashboardClient: DashboardClient,
    private readonly cerebroService: CerebroApiService,
    private notificationService: NotificationService,
    private toastService: ToastService,
    public readonly themeService: ThemeService,
    private readonly modalService: NgbModal
  ) {
    this.getObjectCount();

    themeService.activeTheme$.subscribe((theme) => {
      this.activeTheme = theme;
    });

    notificationService.assetsChangedEvent$
      .pipe(
        untilDestroyed(this),
        tap(() => this.getObjectCount())
      )
      .subscribe();

    cerebroService.groups$.subscribe((groups) => (this.groups = groups));
  }

  private getObjectCount(): void {
    this.dashboardClient.get().subscribe((result) => (this.totalObjects = result));
  }

  public toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
  }

  public async openModal() {
    this.modalService.open(AssetsAddComponent);
  }
}
