<div class="modal fade" #modalElement id="{{ modalId }}" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <form class="needs-validation" (ngSubmit)="onSubmit()">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Add Module</h1>
          <button (click)="close()" type="button" class="btn-close" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <div style="cursor: pointer" class="card-group">
              <div (click)="editModule.type = ModuleTypeDto.AYB" class="card text-center">
                <div [ngClass]="editModule.type === ModuleTypeDto.AYB ? 'selected-module-type' : ''" class="card-body">
                  <img
                    themedImage
                    srcLight="icons/joystick-ayb-dark.svg"
                    srcDark="icons/joystick-ayb-white.svg"
                    class="pe-none bi"
                    width="45"
                    height="45"
                    alt=""
                  />
                  <h5 class="card-subtitle mt-2 text-body-secondary">AYB</h5>
                </div>
              </div>
              <div (click)="editModule.type = ModuleTypeDto.CAMERA" class="card text-center">
                <div [ngClass]="editModule.type === ModuleTypeDto.CAMERA ? 'selected-module-type' : ''" class="card-body">
                  <img themedImage srcLight="icons/visor-dark.svg" srcDark="icons/visor-white.svg" class="pe-none bi" width="60" height="60" alt="" />
                  <h5 class="card-subtitle text-body-secondary">Camera</h5>
                </div>
              </div>
              <div (click)="editModule.type = ModuleTypeDto.RADAR" class="card text-center">
                <div [ngClass]="editModule.type === ModuleTypeDto.RADAR ? 'selected-module-type' : ''" class="card-body">
                  <img themedImage srcLight="icons/radar-dark.svg" srcDark="icons/radar-white.svg" class="pe-none bi" width="47" height="47" alt="" />
                  <h5 class="card-subtitle mt-2 text-body-secondary">Radar</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-1">
            <label for="module-name" class="col-form-label">Name*</label>
            <input [(ngModel)]="editModule.name" type="text" class="form-control" name="module-name" id="module-name" required="" />
          </div>
          <div *ngIf="editModule.type === ModuleTypeDto.CAMERA" class="mb-1">
            <label class="col-form-label">Source*</label>
            <select [(ngModel)]="editModule.parsedConfig.source" name="module-source" class="form-select">
              <option value="WEBRTC">WebRTC</option>
              <option value="NVR">NVR</option>
            </select>
          </div>
          <div *ngIf="editModule.type === ModuleTypeDto.CAMERA && editModule.parsedConfig.source === 'NVR'" class="mb-1">
            <label for="module-address" class="col-form-label">Address*</label>
            <input
              [(ngModel)]="editModule.parsedConfig.address"
              type="text"
              class="form-control"
              name="module-address"
              placeholder="https://module1.dotocean.eu/"
              id="module-address"
              required=""
            />
          </div>
          <div *ngIf="editModule.type === ModuleTypeDto.CAMERA && editModule.parsedConfig.source === 'WEBRTC'" class="mb-3">
            <label for="module-id" class="col-form-label">ID*</label>
            <input
              [(ngModel)]="editModule.parsedConfig.id"
              type="text"
              class="form-control"
              name="module-id"
              placeholder="Camera ID"
              id="module-id"
              required=""
            />
          </div>
          <div *ngIf="editModule.type !== ModuleTypeDto.CAMERA" class="mb-2 has-validation">
            <label for="module-guid" class="col-form-label">
              GUID*
              <div *ngIf="guidLoading" class="spinner-border spinner-border-sm" role="status"></div>
              <svg *ngIf="moduleFound" style="fill: #0dc142" class="me-1 bi" width="15" height="15">
                <use href="bootstrap-icons.svg#check-circle-fill" />
              </svg>
            </label>
            <input
              [(ngModel)]="editModule.parsedConfig.guid"
              (input)="checkGUID($event)"
              type="text"
              class="form-control"
              name="module-guid"
              id="module-guid"
              required=""
            />
            <div style="display: block !important" *ngIf="invalidGUID" class="invalid-feedback">GUID is not valid.</div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
          <button *ngIf="activeTheme === 'light'" type="submit" class="btn btn-primary">Add Module</button>
          <button *ngIf="activeTheme === 'dark'" type="submit" class="btn btn-light">Add Module</button>
        </div>
      </form>
    </div>
  </div>
</div>
