import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";

@Injectable()
export class ConfigApiService {
    public url = "https://localhost:7228/";

    constructor(private http: HttpClient) { }

    public getConfigFiles(id: string): Promise<any> {
        return firstValueFrom(this.http.get<any>(this.url + `configuration/${id}`));
    }

    public generateConfigsFromTemplates(id: string, module: any) {
        return firstValueFrom(this.http.post<any>(this.url + `configuration/${id}/generate`, module));
    }

    public saveAndPushConfigFiles(id: string, files: any) {
        return firstValueFrom(this.http.post<any>(this.url + `configuration/${id}/push`, files));
    }
}
