import { Asset } from '../services/apis/cloud.service';
import { ModuleExtended } from './module-extended';

export interface AssetExtended extends Asset {
  lastUpdated: string;
  online: boolean;

  modules: ModuleExtended[];
}

export function fromAsset(asset: Asset) {
  const result = {} as AssetExtended;
  Object.assign(result, asset);

  if (result.modules) {
    for (const module of result.modules) {
      module.selectedEventPage = 1;
      if (module.config != null) module.parsedConfig = JSON.parse(module.config);
      else {
        module.parsedConfig = { subModules: { core: true } };
        module.config = JSON.stringify(module.parsedConfig);
      }

      module.selectedConfigFile = { content: '' };
    }
  }

  return result;
}
