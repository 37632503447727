import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { EventType, Router } from '@angular/router';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';
import { ConfigApiService } from '../services/config-api-service';
import { CameraApiService } from '../services/camera-api-service';
import { ObjectsWebSocketService } from '../services/objects-websocket-service';
import { ObjectsApiService } from '../services/objects-api-service';
import { NotificationService } from '../services/shared-service';
import { ToastService } from '../services/toast-service';
import { FleetManagerComponents } from '../shared/fleetmanager-components.module';
import { RadarWebSocketService } from '../services/radar-websocket-service';
import { VirtualworldNgxObjectsSocketService } from '@dotocean/virtualworld-ngx-services';
import {
  FleetManagerWebSocket,
  FleetManagerWebSocketService,
} from '../services/fleetmanager-websocket.service';
import { CerebroApiService } from '../services/cerebro-api-service';
import { ThemeService } from '../services/theme-service';
import { APP_CONFIG, AppConfig } from '../app.config';
import { SidebarComponent } from './sidebar/sidebar.component';
// eslint-disable-next-line no-var
declare var bootstrap: any;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [FleetManagerComponents, SidebarComponent],
  providers: [],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements AfterViewInit {
  @ViewChild(SidebarComponent) sidebar!: SidebarComponent;

  public activeTheme = 'light';
  public selectedLink: string;
  public toast: any;
  public toastText = 'Saved!';
  public alertToast: any;
  public alertToastHeader = '';
  public alertToastMessage = '';

  public fleetManagerSocket: FleetManagerWebSocket;

  constructor(
    private router: Router,
    public authService: AtlantisNgxAuthService,
    configService: ConfigApiService,
    cameraService: CameraApiService,
    private notificationService: NotificationService,
    objectsService: ObjectsApiService,
    objectsWebSocket: ObjectsWebSocketService,
    private toastService: ToastService,
    radarWebSocket: RadarWebSocketService,
    boundsObjectsWebSocket: VirtualworldNgxObjectsSocketService,
    private readonly fleetManagerWebSocket: FleetManagerWebSocketService,
    private readonly cerebroService: CerebroApiService,
    public readonly themeService: ThemeService,
    @Inject(APP_CONFIG) config: AppConfig
  ) {
    authService.url = config.endpoints.api;
    configService.url = config.endpoints.config;
    cameraService.url = config.endpoints.camera;
    objectsService.url = config.endpoints.objects;
    objectsWebSocket.url = config.endpoints.objects;
    radarWebSocket.url = config.endpoints.radar;
    boundsObjectsWebSocket.url = config.endpoints.objects;
    fleetManagerWebSocket.url = config.endpoints.api;
    cerebroService.url = config.endpoints.cerebro;

    themeService.setTheme();

    themeService.activeTheme$.subscribe((theme) => {
      this.activeTheme = theme;
    });

    router.events.subscribe((e) => {
      if (e.type == EventType.NavigationEnd) {
        switch (true) {
          case e.url.indexOf('map') > -1:
            this.selectedLink = 'MAP';
            break;
          case e.url.indexOf('vessels') > -1:
            this.selectedLink = 'VESSELS';
            break;
          case e.url.indexOf('stationary') > -1:
            this.selectedLink = 'STATIONARY';
            break;
          case e.url.indexOf('vehicles') > -1:
            this.selectedLink = 'VEHICLES';
            break;
          case e.url.indexOf('alerts') > -1:
            this.selectedLink = 'ALERTS';
            break;
          default:
            this.selectedLink = 'MAP';
        }

        if (e.url.indexOf('login') != 1) {
          this.notificationService.assetsChanged();

          if (this.fleetManagerSocket == null) {
            this.fleetManagerSocket =
              this.fleetManagerWebSocket.createFleetManagerSocket();
            this.fleetManagerSocket.AlertTriggered.subscribe((data) =>
              this.showAlertToast(data)
            );
            this.fleetManagerSocket.connect();
          }
        }
      }
    });

    this.toastService.showToastEvent.subscribe((evt) => this.showToast(evt));
  }

  public toggleSidebar() {
    this.sidebar.toggleSidebar(); // Call the sidebar's toggleSidebar method
  }

  public showAlertToast(data: any) {
    this.alertToastHeader = 'Alert: ' + data.alertName;
    this.alertToastMessage = `Vessel ${data.mmsi} has been detected in geofence!`;
    if (!this.alertToast.isShown()) this.alertToast.show();
  }

  public showToast(message: string) {
    this.toastText = message;
    this.toast.show();
  }

  public async ngAfterViewInit() {
    this.toast = new bootstrap.Toast('#liveToast');
    this.alertToast = new bootstrap.Toast('#alertToast');
  }

  public async logout() {
    this.authService.logout();
    this.router.navigate(['login']);
  }
}
