import { AssetList } from '../services/apis/cloud.service';
import { ModuleExtended } from './module-extended';

export interface AssetListExtended extends Omit<AssetList, 'modules'> {
  lastUpdated: string;
  online: boolean;
  modules?: ModuleExtended[];
}

export function fromAssetList(asset: AssetList) {
  const result = {} as AssetListExtended;
  Object.assign(result, asset);

  result.modules = asset.modules?.map((module) => {
    const ModuleExtended = {} as ModuleExtended;
    Object.assign(ModuleExtended, module);

    if (module.config) ModuleExtended.parsedConfig = JSON.parse(module.config);

    return ModuleExtended;
  });

  return result;
}
