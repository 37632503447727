<header *ngIf="authService.identity" class="app--header border-bottom">
  <div class="app--header--item">
    <svg style="vertical-align: -9px; cursor: pointer" (click)="toggleSidebar()" class="bi ms-3" width="24" height="24">
      <use href="bootstrap-icons.svg#list" />
    </svg>
    <a href="/#/map" class="d-flex align-items-center ms-3 mb-lg-0 link-body-emphasis text-decoration-none">
      <img
        themedImage
        srcLight="icons/dotocean-cloud-dark.svg"
        srcDark="icons/dotocean-cloud-white.svg"
        class="bi pe-none"
        height="25"
        width="143"
        alt=""
      />
    </a>
  </div>
  <div class="app--header--item">
    <svg (click)="themeService.toggleTheme()" style="cursor: pointer" class="bi me-3 opacity-75" width="20" height="20">
      <use *ngIf="activeTheme === 'dark'" href="bootstrap-icons.svg#sun-fill" />
      <use *ngIf="activeTheme === 'light'" href="bootstrap-icons.svg#moon-stars-fill" />
    </svg>

    <div class="dropdown text-end pt-2 pb-2">
      <a
        href="#"
        class="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle me-3"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <div
          style="width: 32px; height: 32px; background-color: #86d9f0; font-size: 19px; font-weight: 400; color: white; line-height: 30px"
          class="rounded-circle me-2 text-center"
        >
          {{ authService.identity.Session.User.Name.charAt(0) }}
        </div>
        <strong>{{ authService.identity.Session.User.Name }}</strong>
      </a>
      <ul class="dropdown-menu text-small">
        <li (click)="logout()"><a class="dropdown-item" href="#">Sign out</a></li>
      </ul>
    </div>
  </div>
</header>

<div class="container-fluid pe-0 ps-0 d-flex app-container">
  <app-sidebar
    class="app-container--menu"
    style="min-height: calc(100vh - 49px)"
    *ngIf="authService.identity"
    [selectedLink]="selectedLink"
  ></app-sidebar>

  <div class="app-container--content content-area" [ngClass]="!authService.identity ? 'login gx-0' : 'gx-0'">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="toast-container position-fixed top-0 start-50 translate-middle-x p-3">
  <div
    id="liveToast"
    class="toast text-white"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    [class.bg-success]="toastMessage?.type === 'default'"
    [class.bg-warning]="toastMessage?.type === 'warn'"
    [class.bg-danger]="toastMessage?.type === 'error'"
  >
    <div class="d-flex">
      <div class="toast-body">
        {{ toastMessage?.message }}
      </div>
      <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
  </div>
</div>

<div class="toast-container position-fixed top-0 start-50 translate-middle-x p-3">
  <div id="alertToast" class="toast bg-danger text-white" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
    <div class="toast-header bg-danger text-white">
      <svg class="rounded me-2 bi" width="20" height="20">
        <use href="bootstrap-icons.svg#bell" />
      </svg>
      <strong class="me-auto">{{ alertToastHeader }}</strong>
      <button type="button" class="btn-close btn-close-white" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      {{ alertToastMessage }}
    </div>
  </div>
</div>
