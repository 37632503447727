import { inject, Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { FeatureFlagName, canViewFeature } from './feature-flag.service';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';
import { filter, take, map } from 'rxjs';

export function featureFlagGuard(featureFlag: FeatureFlagName): CanActivateFn {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router = inject(Router);
    const authService = inject(AtlantisNgxAuthService);

    return authService.identityLoaded$.pipe(
      filter((loaded) => loaded),
      take(1),
      map(() => {
        if (!authService.identity) {
          // If no session exists, redirect to login.
          router.navigate(['/login']);
          return false;
        }

        const canAccess = canViewFeature(featureFlag, authService.identity);
        if (!canAccess) {
          // If the feature is disabled for the user, redirect to an unauthorized page.
          router.navigate(['/unauthorized']);
        }
        return canAccess;
      })
    );
  };
}
