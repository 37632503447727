import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class CameraApiService {
    public url = "https://localhost:7097/";

    constructor(private http: HttpClient) { }

    public getSingleCamera(id: string): Observable<any> {
        return this.http.get<any>(this.url + `camera/${id}`);
    }

    public getEvents(id: string): Observable<any> {
        return this.http.get<any>(this.url + `event/${id}`);
    }
}
