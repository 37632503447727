<form class="needs-validation" (ngSubmit)="isNew ? addModule() : updateModule()">
  <div class="modal-header">
    <h1 class="modal-title fs-5">{{ isNew ? "Add" : "Update" }} module</h1>
    <button (click)="activeModal.close(undefined)" type="button" class="btn-close" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    @if(isNew){
    <div class="mb-3">
      <div style="cursor: pointer" class="card-group">
        <div (click)="module.type = ModuleTypeDto.AYB" class="card text-center">
          <div [class.selected-card]="module.type === ModuleTypeDto.AYB" class="selectable-card-body card-body">
            <img
              themedImage
              srcLight="icons/joystick-ayb-dark.svg"
              srcDark="icons/joystick-ayb-white.svg"
              class="pe-none bi"
              width="45"
              height="45"
              alt=""
            />
            <h5 class="card-subtitle mt-2 text-body-secondary">AYB</h5>
          </div>
        </div>
        <div (click)="module.type = ModuleTypeDto.CAMERA" class="card text-center">
          <div [class.selected-card]="module.type === ModuleTypeDto.CAMERA" class="selectable-card-body card-body">
            <img themedImage srcLight="icons/visor-dark.svg" srcDark="icons/visor-white.svg" class="pe-none bi" width="60" height="60" alt="" />
            <h5 class="card-subtitle text-body-secondary">Camera</h5>
          </div>
        </div>
        <div (click)="module.type = ModuleTypeDto.RADAR" class="card text-center">
          <div [class.selected-card]="module.type === ModuleTypeDto.RADAR" class="selectable-card-body card-body">
            <img themedImage srcLight="icons/radar-dark.svg" srcDark="icons/radar-white.svg" class="pe-none bi" width="47" height="47" alt="" />
            <h5 class="card-subtitle mt-2 text-body-secondary">Radar</h5>
          </div>
        </div>
      </div>
    </div>
    }
    <div class="mb-1">
      <label for="module-name" class="col-form-label">Name*</label>
      <input [(ngModel)]="module.name" type="text" class="form-control" name="module-name" id="module-name" required="" />
    </div>
    @if(module.type === ModuleTypeDto.CAMERA){
    <div class="mb-1">
      <label class="col-form-label">Source*</label>
      <select [(ngModel)]="module.parsedConfig.source" name="module-source" class="form-select">
        <option value="WEBRTC">WebRTC</option>
        <option value="NVR">NVR</option>
      </select>
    </div>
    <div *ngIf="module.parsedConfig.source === 'NVR'" class="mb-1">
      <label for="module-address" class="col-form-label">Address*</label>
      <input
        [(ngModel)]="module.parsedConfig.address"
        type="text"
        class="form-control"
        name="module-address"
        placeholder="https://module1.dotocean.eu/"
        id="module-address"
        required=""
      />
    </div>
    <div *ngIf="module.parsedConfig.source === 'WEBRTC'" class="mb-3">
      <label for="module-id" class="col-form-label">ID*</label>
      <input
        [(ngModel)]="module.parsedConfig.id"
        type="text"
        class="form-control"
        name="module-id"
        placeholder="Camera ID"
        id="module-id"
        required=""
      />
    </div>
    }@else{

    <div class="mb-2 has-validation">
      <label for="module-guid" class="col-form-label">
        GUID*
        <div *ngIf="guidLoading" class="spinner-border spinner-border-sm" role="status"></div>
        <svg *ngIf="moduleFound" style="fill: #0dc142" class="me-1 bi" width="15" height="15">
          <use href="bootstrap-icons.svg#check-circle-fill" />
        </svg>
      </label>
      <input
        [(ngModel)]="module.parsedConfig.guid"
        (input)="checkGUID($event)"
        type="text"
        class="form-control"
        name="module-guid"
        id="module-guid"
        required=""
      />
      <div style="display: block !important" *ngIf="invalidGUID" class="invalid-feedback">GUID is not valid.</div>
    </div>
    }
  </div>
  <div class="modal-footer">
    @if(!isNew){
    <button *ngIf="!showConfirm" (click)="showConfirm = true" type="button" class="btn btn-outline-danger">Remove</button>
    <button *ngIf="showConfirm" (click)="removeModule()" type="button" class="btn btn-danger" [disabled]="isSaving">Confirm?</button>
    }@else{
    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
    }
    <button type="submit" class="btn" [ngClass]="(themeService.activeTheme$ | async) === 'light' ? 'btn-primary' : 'btn-light'" [disabled]="isSaving">
      {{ isNew ? "Save" : "Update" }} module
    </button>
  </div>
</form>
