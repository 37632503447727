<div [ngClass]="{ collapsed: isCollapsed }" class="sidebar-container bg-body-tertiary border-end">
  <div class="d-flex flex-column flex-shrink-0 p-2">
    <ul class="nav nav-pills flex-column mb-auto">
      <li sidebar-item title="Live map" [routerLink]="['/map']" [isCollapsed]="isCollapsed" bootstrapIcon="bootstrap-icons.svg#globe-americas"></li>
      <li
        sidebar-item
        *ngIf="(totalObjects.vessels ?? 0) > 0"
        title="Vessels"
        [routerLink]="['/vessels']"
        [isCollapsed]="isCollapsed"
        iconLight="icons/vessel-dark.svg"
        iconDark="icons/vessel-light.svg"
        [badgeCount]="totalObjects.vessels"
      ></li>

      <li
        sidebar-item
        *ngIf="(totalObjects.stationary ?? 0) > 0"
        title="Stationary"
        [routerLink]="['/stationary']"
        [isCollapsed]="isCollapsed"
        iconLight="icons/static-dark.svg"
        iconDark="icons/static-light.svg"
        [badgeCount]="totalObjects.stationary"
      ></li>

      <li
        sidebar-item
        *ngIf="(totalObjects.vehicles ?? 0) > 0"
        title="Vehicles"
        [routerLink]="['/vehicles']"
        [isCollapsed]="isCollapsed"
        iconLight="icons/vehicle-dark.svg"
        iconDark="icons/vehicle-light.svg"
        [badgeCount]="totalObjects.vehicles"
      ></li>

      <hr style="margin: 5px 0px" />
      <li
        sidebar-item
        title="Towage"
        [routerLink]="['/events']"
        [queryParams]="{ type: eventTypeDto.TOWAGE }"
        [isCollapsed]="isCollapsed"
        faIcon="fa-solid fa-trowel"
      ></li>
      <hr style="margin: 5px 0px" />
      <li sidebar-item title="Alerts" [routerLink]="['/alerts']" [isCollapsed]="isCollapsed" bootstrapIcon="bootstrap-icons.svg#bell"></li>
    </ul>

    <button [ngClass]="activeTheme === 'light' ? 'btn-outline-primary' : 'btn-outline-light'" (click)="openModal()" type="button" class="btn mt-2">
      <svg style="vertical-align: -6px" class="bi pe-none" width="24" height="24">
        <use href="bootstrap-icons.svg#plus" />
      </svg>
      <span *ngIf="!isCollapsed">Add Asset</span>
    </button>
  </div>
</div>
