import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MapComponent } from './map/map.component';
import { AtlantisNgxAuthGuard } from '@dotocean/atlantis-ngx-auth';
import { AlertsComponent } from './alerts/alerts.component';
import { AssetListComponent } from './assets/assets.component';
import { AssetDetailComponent } from './assets/assets-detail/assets-detail.component';
import { featureFlagGuard } from '../services/features/feature-flag.guard';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'map', component: MapComponent, canActivate: [AtlantisNgxAuthGuard] },
  { path: 'vessels', component: AssetListComponent, canActivate: [AtlantisNgxAuthGuard], data: { type: 'VESSEL' } },
  { path: 'vessels/:id', component: AssetDetailComponent, canActivate: [AtlantisNgxAuthGuard] },
  { path: 'stationary', component: AssetListComponent, canActivate: [AtlantisNgxAuthGuard], data: { type: 'STATIONARY' } },
  { path: 'stationary/:id', component: AssetDetailComponent, canActivate: [AtlantisNgxAuthGuard] },
  { path: 'vehicles', component: AssetListComponent, canActivate: [AtlantisNgxAuthGuard], data: { type: 'VEHICLE' } },
  { path: 'vehicles/:id', component: AssetDetailComponent, canActivate: [AtlantisNgxAuthGuard] },
  { path: 'alerts', component: AlertsComponent, canActivate: [AtlantisNgxAuthGuard, featureFlagGuard('ALLOW_ALERTS')] },

  {
    path: 'events',
    loadChildren: () => import('./events/events.routes').then((mod) => mod.EVENTS_ROUTES),
    canActivate: [AtlantisNgxAuthGuard, featureFlagGuard('ALLOW_TOWAGE')],
  },
];
