import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';
import { FleetManagerComponents } from '../../shared/fleetmanager-components.module';
import { ThemeService } from '../../services/theme-service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FleetManagerComponents],
  providers: [],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  public email: string;
  public password: string;
  public isLoading = false;
  public invalidIdentity = false;

  constructor(
    private router: Router,
    private authService: AtlantisNgxAuthService,
    public readonly themeService: ThemeService
  ) {}

  public async ngOnInit() {
    if (this.authService.identity != null) this.router.navigate(['vessels']);
  }

  public async login() {
    this.isLoading = true;
    const identity = await this.authService.login(this.email, this.password);
    this.isLoading = false;

    if (identity != null) {
      this.invalidIdentity = false;
      this.router.navigate(['map']);
    } else this.invalidIdentity = true;
  }
}
