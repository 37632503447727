import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class ObjectsApiService {
    public url = "https://api.dotocean.eu/object-monitor/";

    constructor(private http: HttpClient) { }

    public getRadars(): Observable<any> {
        return this.http.get(this.url + `sources`);
    }

    public getSingleRadar(id: string): Observable<any> {
        return this.http.get(this.url + `sources/${id}`);
    }

    public getSingleObject(id: number): Observable<any> {
        return this.http.get(this.url + `objects/${id}`);
    }
}
