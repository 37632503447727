import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";

@Injectable()
export class NVRApiService {
    constructor(private http: HttpClient) { }

    public getConfig(config: any): Promise<any> {
        return firstValueFrom(this.http.get<any>(`${config.address}/api/config`));
    }

    // public getEventClip(id: string): Promise<any> {
    //     return firstValueFrom(this.http.get<any>("http://localhost:4200/" + `api/events/${id}/clip.mp4`));
    // }
}