<div class="container-fluid" *ngIf="asset">
  <div class="card mb-2 border-0 border-bottom rounded-0">
    <div class="card-body ps-0 pt-1 pb-1">
      <div class="row detail-header justify-content-between align-items-center flex-grow-1f">
        <div class="col-md">
          <svg routerLink=".." style="vertical-align: -12px; cursor: pointer" class="bi" width="40" height="40">
            <use href="bootstrap-icons.svg#arrow-left-short" />
          </svg>
          <span class="card-header-title h4">{{ asset.name }}</span>
          <small *ngIf="asset.uuid !== null && asset.uuid !== 0">({{ asset.uuid }})</small>
        </div>
        <div class="col-auto">
          <svg
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title="Update asset"
            (click)="openUpdateAssetModal()"
            style="vertical-align: -9px; cursor: pointer"
            class="bi"
            width="24"
            height="24"
          >
            <use href="bootstrap-icons.svg#gear" />
          </svg>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid d-flex ps-0 pe-0">
    <div style="min-width: 75px" *ngIf="!modulesVisible && !assetLoading">
      <div class="card">
        <div class="card-header">
          <div class="card-title d-flex">
            <svg style="vertical-align: -6px" class="bi pe-none me-2" width="24" height="24">
              <use xlink:href="bootstrap-icons.svg#boxes"></use>
            </svg>
            <svg (click)="toggleModules()" style="margin-right: -10px; cursor: pointer" class="bi" width="24" height="24">
              <use href="bootstrap-icons.svg#chevron-bar-right" />
            </svg>
          </div>
        </div>
        <ul
          *ngIf="
            (asset.modules | enumFilter : ModuleTypeDto.AYB).length > 0 ||
            (asset.modules | enumFilter : ModuleTypeDto.CAMERA).length > 0 ||
            (asset.modules | enumFilter : ModuleTypeDto.RADAR).length > 0
          "
          class="list-unstyled p-0 mb-0 card-body text-center"
        >
          <li *ngIf="(asset.modules | enumFilter : ModuleTypeDto.AYB).length > 0" class="mb-0">
            <button
              class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#ayb-collapse"
              aria-expanded="true"
            >
              <svg class="me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 14l6-6-6-6" />
              </svg>
              {{ (asset.modules | enumFilter : ModuleTypeDto.AYB).length }}
            </button>
            <div class="collapse show mt-1" id="ayb-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li *ngFor="let module of asset.modules | enumFilter : ModuleTypeDto.AYB">
                  <a
                    [ngClass]="selectedModule?.id === module.id ? 'highlight' : ''"
                    style="font-size: 12px; cursor: pointer"
                    (click)="selectModule(module)"
                    class="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <img
                      *ngIf="selectedModule?.id === module.id || activeTheme === 'light'"
                      class="pe-none bi"
                      width="30"
                      height="30"
                      src="icons/joystick-ayb-dark.svg"
                      alt=""
                    />
                    <img
                      *ngIf="selectedModule?.id !== module.id && activeTheme === 'dark'"
                      class="pe-none bi"
                      width="30"
                      height="30"
                      src="icons/joystick-ayb-white.svg"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li *ngIf="(asset.modules | enumFilter : ModuleTypeDto.CAMERA).length > 0" class="mb-0">
            <button
              class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#camera-collapse"
              aria-expanded="true"
            >
              <svg class="me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 14l6-6-6-6" />
              </svg>
              {{ (asset.modules | enumFilter : ModuleTypeDto.CAMERA).length }}
            </button>
            <div class="collapse show mt-1" id="camera-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li *ngFor="let module of asset.modules | enumFilter : ModuleTypeDto.CAMERA">
                  <a
                    [ngClass]="selectedModule?.id === module.id ? 'highlight' : ''"
                    style="font-size: 12px; cursor: pointer"
                    (click)="selectModule(module)"
                    class="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <img
                      *ngIf="selectedModule?.id === module.id || activeTheme === 'light'"
                      class="pe-none bi"
                      width="30"
                      height="30"
                      src="icons/visor-dark.svg"
                      alt=""
                    />
                    <img
                      *ngIf="selectedModule?.id !== module.id && activeTheme === 'dark'"
                      class="pe-none bi"
                      width="30"
                      height="30"
                      src="icons/visor-white.svg"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li *ngIf="(asset.modules | enumFilter : ModuleTypeDto.RADAR).length > 0" class="mb-0">
            <button
              class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#radar-collapse"
              aria-expanded="true"
            >
              <svg class="me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 14l6-6-6-6" />
              </svg>
              {{ (asset.modules | enumFilter : ModuleTypeDto.RADAR).length }}
            </button>
            <div class="collapse show mt-1" id="radar-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li *ngFor="let module of asset.modules | enumFilter : ModuleTypeDto.RADAR">
                  <a
                    [ngClass]="selectedModule?.id === module.id ? 'highlight' : ''"
                    style="font-size: 12px; cursor: pointer"
                    (click)="selectModule(module)"
                    class="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <img
                      *ngIf="selectedModule?.id === module.id || activeTheme === 'light'"
                      class="pe-none bi"
                      width="25"
                      height="25"
                      src="icons/radar-dark.svg"
                      alt=""
                    />
                    <img
                      *ngIf="selectedModule?.id !== module.id && activeTheme === 'dark'"
                      class="pe-none bi"
                      width="25"
                      height="25"
                      src="icons/radar-white.svg"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <button *ngIf="activeTheme === 'light'" (click)="openAddModuleModal()" type="button" class="btn btn-outline-primary m-2">
          <svg style="vertical-align: -6px" class="bi pe-none" width="24" height="24">
            <use href="bootstrap-icons.svg#plus" />
          </svg>
        </button>
        <button *ngIf="activeTheme === 'dark'" (click)="openAddModuleModal()" type="button" class="btn btn-outline-light m-2">
          <svg style="vertical-align: -6px" class="bi pe-none" width="24" height="24">
            <use href="bootstrap-icons.svg#plus" />
          </svg>
        </button>
      </div>
    </div>

    <div style="min-width: 250px" *ngIf="modulesVisible && !assetLoading">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            <svg style="vertical-align: -6px" class="bi pe-none me-2" width="30" height="30">
              <use xlink:href="bootstrap-icons.svg#boxes"></use>
            </svg>
            Modules
            <svg
              (click)="toggleModules()"
              style="margin-top: 5px; margin-right: -10px; cursor: pointer; float: right"
              class="bi"
              width="24"
              height="24"
            >
              <use href="bootstrap-icons.svg#chevron-bar-left" />
            </svg>
          </h5>
        </div>
        <ul
          *ngIf="
            (asset.modules | enumFilter : ModuleTypeDto.AYB).length > 0 ||
            (asset.modules | enumFilter : ModuleTypeDto.CAMERA).length > 0 ||
            (asset.modules | enumFilter : ModuleTypeDto.RADAR).length > 0
          "
          class="list-unstyled ps-0 mb-0 ms-2"
        >
          <li *ngIf="(asset.modules | enumFilter : ModuleTypeDto.AYB).length > 0" class="mb-0">
            <button
              class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#ayb-collapse"
              aria-expanded="true"
            >
              <svg class="me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 14l6-6-6-6" />
              </svg>
              AYB's ({{ (asset.modules | enumFilter : ModuleTypeDto.AYB).length }})
            </button>
            <div class="collapse show mt-1" id="ayb-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li *ngFor="let module of asset.modules | enumFilter : ModuleTypeDto.AYB">
                  <a
                    [ngClass]="selectedModule?.id === module.id ? 'highlight' : ''"
                    style="font-size: 12px; cursor: pointer"
                    (click)="selectModule(module)"
                    class="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <img
                      *ngIf="selectedModule?.id === module.id || activeTheme === 'light'"
                      class="pe-none bi me-2"
                      width="20"
                      height="20"
                      src="icons/joystick-ayb-dark.svg"
                      alt=""
                    />
                    <img
                      *ngIf="selectedModule?.id !== module.id && activeTheme === 'dark'"
                      class="pe-none bi me-2"
                      width="20"
                      height="20"
                      src="icons/joystick-ayb-white.svg"
                      alt=""
                    />

                    <span style="max-width: 125px" class="text-truncate" *ngIf="selectedModule?.id === module.id || activeTheme === 'dark'">
                      {{ module.name }}
                    </span>
                    <span style="max-width: 125px" class="text-truncate" *ngIf="selectedModule?.id !== module.id && activeTheme === 'light'">
                      {{ module.name }}
                    </span>
                  </a>

                  <svg
                    *ngIf="selectedModule?.id === module.id"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="Update module"
                    (click)="openUpdateModuleModal()"
                    style="cursor: pointer; margin-top: 5px; margin-right: 10px; float: right"
                    class="bi"
                    width="20"
                    height="20"
                  >
                    <use href="bootstrap-icons.svg#gear" />
                  </svg>
                </li>
              </ul>
            </div>
          </li>
          <li *ngIf="(asset.modules | enumFilter : ModuleTypeDto.CAMERA).length > 0" class="mb-0">
            <button
              class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#camera-collapse"
              aria-expanded="true"
            >
              <svg class="me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 14l6-6-6-6" />
              </svg>
              Cameras ({{ (asset.modules | enumFilter : ModuleTypeDto.CAMERA).length }})
            </button>
            <div class="collapse show mt-1" id="camera-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li *ngFor="let module of asset.modules | enumFilter : ModuleTypeDto.CAMERA">
                  <svg
                    [ngStyle]="{ fill: module.webrtcConnection?.videoStarted ? '#2A8E60' : '#717171' }"
                    style="margin-top: -13px; margin-right: 5px"
                    width="20"
                    height="20"
                  >
                    <use href="bootstrap-icons.svg#circle-fill" />
                  </svg>

                  <a
                    [ngClass]="selectedModule?.id === module.id ? 'highlight' : ''"
                    style="font-size: 12px; cursor: pointer"
                    (click)="selectModule(module)"
                    class="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <img
                      *ngIf="selectedModule?.id === module.id || activeTheme === 'light'"
                      class="pe-none bi me-2"
                      width="20"
                      height="20"
                      src="icons/visor-dark.svg"
                      alt=""
                    />
                    <img
                      *ngIf="selectedModule?.id !== module.id && activeTheme === 'dark'"
                      class="pe-none bi me-2"
                      width="20"
                      height="20"
                      src="icons/visor-white.svg"
                      alt=""
                    />

                    <span style="max-width: 125px" class="text-truncate" *ngIf="selectedModule?.id === module.id || activeTheme === 'dark'">
                      {{ module.name }}
                    </span>
                    <span style="max-width: 125px" class="text-truncate" *ngIf="selectedModule?.id !== module.id && activeTheme === 'light'">
                      {{ module.name }}
                    </span>
                  </a>

                  <svg
                    *ngIf="selectedModule?.id === module.id"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="Update module"
                    (click)="openUpdateModuleModal()"
                    style="cursor: pointer; margin-top: 5px; margin-right: 10px; float: right"
                    class="bi"
                    width="20"
                    height="20"
                  >
                    <use href="bootstrap-icons.svg#gear" />
                  </svg>
                </li>
              </ul>
            </div>
          </li>
          <li *ngIf="(asset.modules | enumFilter : ModuleTypeDto.RADAR).length > 0" class="mb-0">
            <button
              class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#radar-collapse"
              aria-expanded="true"
            >
              <svg class="me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 14l6-6-6-6" />
              </svg>
              Radars ({{ (asset.modules | enumFilter : ModuleTypeDto.RADAR).length }})
            </button>
            <div class="collapse show mt-1" id="radar-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li *ngFor="let module of asset.modules | enumFilter : ModuleTypeDto.RADAR">
                  <svg
                    [ngStyle]="{ fill: module.radarSocket?.isConnected ? '#2A8E60' : '#717171' }"
                    style="margin-top: -13px; margin-right: 5px"
                    width="20"
                    height="20"
                  >
                    <use href="bootstrap-icons.svg#circle-fill" />
                  </svg>

                  <a
                    [ngClass]="selectedModule?.id === module.id ? 'highlight' : ''"
                    style="font-size: 12px; cursor: pointer"
                    (click)="selectModule(module)"
                    class="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    <img
                      *ngIf="selectedModule?.id === module.id || activeTheme === 'light'"
                      class="pe-none bi me-2"
                      width="20"
                      height="20"
                      src="icons/radar-dark.svg"
                      alt=""
                    />
                    <img
                      *ngIf="selectedModule?.id !== module.id && activeTheme === 'dark'"
                      class="pe-none bi me-2"
                      width="20"
                      height="20"
                      src="icons/radar-white.svg"
                      alt=""
                    />

                    <span style="max-width: 125px" class="text-truncate" *ngIf="selectedModule?.id === module.id || activeTheme === 'dark'">
                      {{ module.name }}
                    </span>
                    <span style="max-width: 125px" class="text-truncate" *ngIf="selectedModule?.id !== module.id && activeTheme === 'light'">
                      {{ module.name }}
                    </span>
                  </a>

                  <svg
                    *ngIf="selectedModule?.id === module.id"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="Update module"
                    (click)="openUpdateModuleModal()"
                    style="cursor: pointer; margin-top: 5px; margin-right: 10px; float: right"
                    class="bi"
                    width="20"
                    height="20"
                  >
                    <use href="bootstrap-icons.svg#gear" />
                  </svg>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <button *ngIf="activeTheme === 'light'" (click)="openAddModuleModal()" type="button" class="btn btn-outline-primary m-2">
          <svg style="vertical-align: -6px" class="bi pe-none" width="24" height="24">
            <use href="bootstrap-icons.svg#plus" />
          </svg>
          Add Module
        </button>
        <button *ngIf="activeTheme === 'dark'" (click)="openAddModuleModal()" type="button" class="btn btn-outline-light m-2">
          <svg style="vertical-align: -6px" class="bi pe-none" width="24" height="24">
            <use href="bootstrap-icons.svg#plus" />
          </svg>
          Add Module
        </button>
      </div>
    </div>

    <div *ngIf="selectedModule && !assetLoading" class="container-fluid pe-0">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li *ngIf="selectedModule.type === ModuleTypeDto.CAMERA" class="nav-item" role="presentation">
          <button
            (click)="selectedTab = 'livestream'"
            [ngClass]="selectedTab === 'livestream' ? 'active' : ''"
            class="nav-link"
            type="button"
            role="tab"
          >
            Live stream
          </button>
        </li>
        <li *ngIf="selectedModule.type === ModuleTypeDto.RADAR" class="nav-item" role="presentation">
          <button (click)="selectedTab = 'images'" [ngClass]="selectedTab === 'images' ? 'active' : ''" class="nav-link" type="button" role="tab">
            Images
          </button>
        </li>
        <li *ngIf="authService.identity?.Session?.Company?.Id === 2" class="nav-item" role="presentation">
          <button
            (click)="selectedTab = 'submodules'"
            [ngClass]="selectedTab === 'submodules' ? 'active' : ''"
            class="nav-link"
            type="button"
            role="tab"
          >
            Submodules
          </button>
        </li>
        <!-- <li *ngIf="authService.identity?.Session?.Company?.Id==2" class="nav-item" role="presentation">
                            <button (click)="selectedTab='configuration'"
                                [ngClass]="selectedTab=='configuration'?'active':''" class="nav-link" type="button"
                                role="tab">Configuration</button>
                        </li> -->
        <!-- <li *ngIf="selectedModule.type=='CAMERA'&&selectedModule.parsedConfig.subModules.recordings"
                            class="nav-item" role="presentation">
                            <button (click)="selectedTab='events'" [ngClass]="selectedTab=='events'?'active':''"
                                class="nav-link" type="button" role="tab">Events
                                ({{selectedModule.events.length}})</button>
                        </li> -->
        <!-- <li *ngIf="selectedModule.type=='CAMERA'&&selectedModule.parsedConfig.subModules.recordings"
                            class="nav-item" role="presentation">
                            <button (click)="selectedTab='settings'" [ngClass]="selectedTab=='settings'?'active':''"
                                class="nav-link" type="button" role="tab">Settings</button>
                        </li> -->
      </ul>
      <div class="tab-content">
        <div *ngIf="selectedTab === 'submodules'" class="tab-pane fade show active mt-2" role="tabpanel">
          <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
            <div *ngIf="selectedModule.type === ModuleTypeDto.AYB" class="col">
              <div [ngClass]="activeTheme === 'light' ? 'border-primary' : 'border-light'" class="card rounded-3 shadow-sm h-100">
                <div [ngClass]="activeTheme === 'light' ? 'border-primary text-bg-primary' : 'border-light text-bg-light'" class="card-header py-3">
                  <h4 class="my-0 fw-normal">Core</h4>
                </div>
                <div class="card-body d-flex align-items-center justify-content-center">
                  <ul class="list-unstyled mt-2 mb-4">
                    <li>Navigate autonomously</li>
                    <li>Give tasks to control your vessel</li>
                    <li>Cloud connected</li>
                  </ul>
                </div>
              </div>
            </div>
            <div *ngIf="selectedModule.type === ModuleTypeDto.AYB" class="col">
              <div [ngClass]="selectedModule.parsedConfig.subModules.vw ? 'border-primary' : ''" class="card rounded-3 shadow-sm h-100">
                <div [ngClass]="selectedModule.parsedConfig.subModules.vw ? 'text-bg-primary border-primary' : ''" class="card-header py-3">
                  <h4 class="my-0 fw-normal">Virtual World</h4>
                </div>
                <div class="card-body">
                  <ul class="list-unstyled mt-2 mb-4">
                    <li>Build virtual navigation maps</li>
                    <li>Based on actual geo data</li>
                    <li>Path planning capabilities</li>
                  </ul>
                  <button
                    *ngIf="!selectedModule.parsedConfig.subModules.vw && activeTheme === 'light'"
                    (click)="addSubModule('vw')"
                    type="button"
                    class="w-100 btn btn-lg btn-outline-primary"
                  >
                    <span *ngIf="subModuleLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span *ngIf="!subModuleLoading">Add</span>
                  </button>
                  <button
                    *ngIf="!selectedModule.parsedConfig.subModules.vw && activeTheme === 'dark'"
                    (click)="addSubModule('vw')"
                    type="button"
                    class="w-100 btn btn-lg btn-light-primary"
                  >
                    <span *ngIf="subModuleLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span *ngIf="!subModuleLoading">Add</span>
                  </button>
                  <button
                    *ngIf="selectedModule.parsedConfig.subModules.vw"
                    (click)="removeSubModule('vw')"
                    type="button"
                    class="w-100 btn btn-lg btn-outline-danger"
                  >
                    <span *ngIf="subModuleLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span *ngIf="!subModuleLoading">Remove</span>
                  </button>
                </div>
              </div>
            </div>

            <div *ngIf="selectedModule.type === ModuleTypeDto.RADAR" class="col">
              <div [ngClass]="activeTheme === 'light' ? 'border-primary' : 'border-light'" class="card rounded-3 shadow-sm h-100">
                <div [ngClass]="activeTheme === 'light' ? 'border-primary text-bg-primary' : 'border-light text-bg-light'" class="card-header py-3">
                  <h4 class="my-0 fw-normal">Core</h4>
                </div>
                <div class="card-body d-flex align-items-center justify-content-center">
                  <ul class="list-unstyled mt-2 mb-4">
                    <li>Detect objects</li>
                    <li>Determine distance</li>
                    <li>Tracks objects</li>
                  </ul>
                </div>
              </div>
            </div>
            <div *ngIf="selectedModule.type === ModuleTypeDto.RADAR" class="col">
              <div [ngClass]="selectedModule.parsedConfig.subModules.drot ? 'border-primary' : ''" class="card rounded-3 shadow-sm h-100">
                <div [ngClass]="selectedModule.parsedConfig.subModules.drot ? 'text-bg-primary border-primary' : ''" class="card-header py-3">
                  <h4 class="my-0 fw-normal">DROT</h4>
                </div>
                <div class="card-body">
                  <ul class="list-unstyled mt-2 mb-4">
                    <li>Dynamic Object Detection/Tracking</li>
                    <li>Distribute detection/tracks</li>
                    <li>to other networks</li>
                  </ul>
                  <button
                    *ngIf="!selectedModule.parsedConfig.subModules.drot && activeTheme === 'light'"
                    (click)="addSubModule('drot')"
                    type="button"
                    class="w-100 btn btn-lg btn-outline-primary"
                  >
                    <span *ngIf="subModuleLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span *ngIf="!subModuleLoading">Add</span>
                  </button>
                  <button
                    *ngIf="!selectedModule.parsedConfig.subModules.drot && activeTheme === 'dark'"
                    (click)="addSubModule('drot')"
                    type="button"
                    class="w-100 btn btn-lg btn-outline-light"
                  >
                    <span *ngIf="subModuleLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span *ngIf="!subModuleLoading">Add</span>
                  </button>
                  <button
                    *ngIf="selectedModule.parsedConfig.subModules.drot"
                    (click)="removeSubModule('drot')"
                    type="button"
                    class="w-100 btn btn-lg btn-outline-danger"
                  >
                    <span *ngIf="subModuleLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span *ngIf="!subModuleLoading">Remove</span>
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="selectedModule.type === ModuleTypeDto.CAMERA" class="col">
              <div [ngClass]="activeTheme === 'light' ? 'border-primary' : 'border-light'" class="card rounded-3 shadow-sm h-100">
                <div [ngClass]="activeTheme === 'light' ? 'border-primary text-bg-primary' : 'border-light text-bg-light'" class="card-header py-3">
                  <h4 class="my-0 fw-normal">Core</h4>
                </div>
                <div class="card-body d-flex align-items-center justify-content-center">
                  <ul class="list-unstyled mt-2 mb-4">
                    <li>Records video</li>
                    <li>View saved video</li>
                    <li>Livestream</li>
                  </ul>
                </div>
              </div>
            </div>
            <div *ngIf="selectedModule.type === ModuleTypeDto.CAMERA" class="col">
              <div [ngClass]="selectedModule.parsedConfig.subModules.recordings ? 'border-primary' : ''" class="card rounded-3 shadow-sm h-100">
                <div [ngClass]="selectedModule.parsedConfig.subModules.recordings ? 'text-bg-primary border-primary' : ''" class="card-header py-3">
                  <h4 class="my-0 fw-normal">Recordings</h4>
                </div>
                <div class="card-body">
                  <ul class="list-unstyled mt-2 mb-4">
                    <li>Activity recognition</li>
                    <li>Motion detection</li>
                    <li>Provides remote access</li>
                  </ul>
                  <button
                    *ngIf="!selectedModule.parsedConfig.subModules.recordings && activeTheme === 'light'"
                    (click)="addSubModule('recordings')"
                    type="button"
                    class="w-100 btn btn-lg btn-outline-primary"
                  >
                    <span *ngIf="subModuleLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span *ngIf="!subModuleLoading">Add</span>
                  </button>
                  <button
                    *ngIf="!selectedModule.parsedConfig.subModules.recordings && activeTheme === 'dark'"
                    (click)="addSubModule('recordings')"
                    type="button"
                    class="w-100 btn btn-lg btn-outline-light"
                  >
                    <span *ngIf="subModuleLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span *ngIf="!subModuleLoading">Add</span>
                  </button>
                  <button
                    *ngIf="selectedModule.parsedConfig.subModules.recordings"
                    (click)="removeSubModule('recordings')"
                    type="button"
                    class="w-100 btn btn-lg btn-outline-danger"
                  >
                    <span *ngIf="subModuleLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span *ngIf="!subModuleLoading">Remove</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab === 'configuration'" class="tab-pane fade show active" role="tabpanel">
          <div
            *ngIf="selectedModule.type === ModuleTypeDto.AYB || selectedModule.type === ModuleTypeDto.RADAR"
            class="row justify-content-between align-items-center"
          >
            <div class="col-md">
              <select [(ngModel)]="selectedModule.selectedConfigFile" class="form-select" aria-label="Default select example">
                <option *ngFor="let file of selectedModule.configFiles" [ngValue]="file">
                  {{ file.name }}
                </option>
              </select>
            </div>
            <div class="col-auto">
              <button (click)="generateConfigs()" type="button" class="btn btn-outline-primary">Generate configs from templates</button>
              <button (click)="saveAndPush()" type="button" class="btn btn-outline-primary ms-1 me-1">Save & Push</button>
              <button disabled type="button" class="btn btn-warning">Deploy to asset</button>
            </div>
          </div>
          <div class="mt-2" style="height: calc(100vh - 205px)">
            <ngx-monaco-editor
              style="height: 100%"
              [options]="editorOptions"
              [(ngModel)]="selectedModule.selectedConfigFile.content"
            ></ngx-monaco-editor>
          </div>
        </div>
        <div *ngIf="selectedTab === 'events'" class="tab-pane fade show active" role="tabpanel">
          <div class="row justify-content-between align-items-center">
            <div class="col-md">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="width: 66px" scope="col"></th>
                    <th style="width: 200px" scope="col">Date</th>
                    <th scope="col">Camera</th>
                    <th scope="col">Label</th>
                    <th style="width: 66px" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let event of selectedModule.shownEvents">
                    <th>
                      <img
                        (click)="selectedImage = event.image; openEnlargeThumbnailModal()"
                        style="cursor: pointer"
                        [src]="'data:image/jpeg;base64, ' + event.image"
                        height="50"
                        alt=""
                      />
                    </th>
                    <td>
                      {{ moment.unix(event.timestamp).local().format("DD/MM/YYYY HH:mm:ss") }}
                    </td>
                    <td>{{ event.camera }}</td>
                    <td>{{ event.label }}</td>
                    <td>
                      <svg style="cursor: pointer; color: #0d6efd" (click)="openClipModal(event.id)" class="me-1 bi" width="15" height="15">
                        <use href="bootstrap-icons.svg#cloud-download-fill" />
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </table>
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li
                    style="cursor: pointer"
                    [ngClass]="selectedModule.selectedEventPage === 1 ? 'disabled' : ''"
                    (click)="selectedModule.selectedEventPage = selectedModule.selectedEventPage - 1; updateEventsList(selectedModule)"
                    class="page-item"
                  >
                    <a class="page-link" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li
                    style="cursor: pointer"
                    [ngClass]="selectedModule.selectedEventPage === entry ? 'active' : ''"
                    (click)="selectedModule.selectedEventPage = entry; updateEventsList(selectedModule)"
                    *ngFor="let entry of pageNumbers"
                    class="page-item"
                  >
                    <a class="page-link">{{ entry }}</a>
                  </li>
                  <li
                    style="cursor: pointer"
                    [ngClass]="selectedModule.selectedEventPage === totalPages ? 'disabled' : ''"
                    (click)="selectedModule.selectedEventPage = selectedModule.selectedEventPage + 1; updateEventsList(selectedModule)"
                    class="page-item"
                  >
                    <a class="page-link" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab === 'settings'" class="tab-pane fade show active mt-2" role="tabpanel">
          <div style="width: 400px">
            <div class="mb-3">
              <label for="customRange1" class="form-label">Number of days stored ({{ numberOfDaysStored }})</label>
              <input [(ngModel)]="numberOfDaysStored" min="1" max="30" type="range" class="form-range" id="customRange1" />
            </div>
            <button class="btn btn-primary">Save</button>
          </div>
        </div>
        <div *ngIf="selectedTab === 'livestream'" class="tab-pane fade show active" role="tabpanel">
          <div *ngFor="let module of asset.modules">
            <div *ngIf="module.id === selectedModule.id">
              <div *ngIf="module.parsedConfig.source === 'WEBRTC'" class="video-container">
                <div class="card">
                  <div class="card-header">
                    {{ module.parsedConfig.id }}
                    <button
                      (click)="stopVideo()"
                      *ngIf="module.webrtcConnection?.videoStarted"
                      [ngClass]="activeTheme === 'light' ? 'btn-primary' : 'btn-light'"
                      style="margin-left: 5px"
                      type="button"
                      class="btn btn-sm"
                    >
                      Stop Stream
                    </button>
                  </div>
                  <div style="overflow: hidden; margin: 0; padding: 0" class="card-body">
                    <div class="container-fluid text-center m-0 p-0">
                      <div class="row">
                        <div class="col-10 position-relative">
                          <div class="ratio ratio-16x9">
                            <video [id]="'videoElement' + module.id" controls autoplay class="w-100"></video>
                          </div>
                          <div
                            *ngIf="module.webrtcConnection?.videoStarted && module.webrtcConnection?.isPTZ"
                            class="zoomControl position-absolute"
                            style="bottom: 10px; right: 10px"
                          >
                            <svg (click)="zoomIn()" fill="white" width="30" height="30">
                              <use href="bootstrap-icons.svg#zoom-in" />
                            </svg>
                            <svg (click)="zoomOut()" class="mt-2" fill="white" width="30" height="30">
                              <use href="bootstrap-icons.svg#zoom-out" />
                            </svg>
                          </div>
                          <svg
                            (click)="startVideo()"
                            *ngIf="!module.webrtcConnection?.videoStarted && !module.webrtcConnection?.videoLoading"
                            class="playButton position-absolute"
                            style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
                          >
                            <use href="bootstrap-icons.svg#play-fill" />
                          </svg>
                          <div *ngIf="module.webrtcConnection?.videoLoading" class="spinner-border videoLoader text-light" role="status"></div>
                        </div>
                        <div class="col-2 position-relative">
                          <div *ngIf="module.webrtcConnection?.videoStarted && module.webrtcConnection?.presets.length > 0">
                            <div class="dropdown mt-1">
                              <button
                                [ngClass]="activeTheme === 'light' ? 'btn-primary' : 'btn-light'"
                                class="btn dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Presets
                              </button>
                              <ul style="cursor: pointer" class="dropdown-menu">
                                <li *ngFor="let preset of module.webrtcConnection?.presets">
                                  <a (click)="goToPreset(preset.name)" class="dropdown-item">{{ preset.name }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div *ngIf="module.webrtcConnection?.videoStarted && module.webrtcConnection?.tours.length > 0">
                            <div class="dropdown mt-2">
                              <button
                                [ngClass]="activeTheme === 'light' ? 'btn-primary' : 'btn-light'"
                                class="btn dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Tours
                              </button>
                              <ul style="cursor: pointer" class="dropdown-menu">
                                <li *ngFor="let tour of module.webrtcConnection?.tours">
                                  <a [ngClass]="tour.action === 'start' ? 'active' : ''" (click)="activateTour(tour.name)" class="dropdown-item">
                                    {{ tour.name }}
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <button (click)="stopTour()" type="button" class="btn btn-danger mt-2">Stop Tour</button>
                          </div>
                          <app-ngx-joystick
                            *ngIf="module.webrtcConnection?.videoStarted && module.webrtcConnection?.isPTZ"
                            class="joystick"
                            #joystick
                            (move)="joystickMove($event)"
                            (end)="joystickEnd()"
                            [options]="joystickOptions"
                          ></app-ngx-joystick>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <iframe
                class="video-container"
                *ngIf="module.parsedConfig.source === 'NVR'"
                id="iframe"
                style="width: 100%; height: calc(100vh - 174px)"
                [src]="nvrURL"
              ></iframe>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab === 'images'" class="mt-2 text-center">
          <img *ngIf="selectedModule.radarImage !== undefined" [src]="selectedModule.radarImage" alt="" width="600" height="600" />
          <div class="lead mt-5" *ngIf="selectedModule.radarImage === undefined">Currently not receiving any images from radar</div>
        </div>
      </div>
    </div>

    <div *ngIf="asset.modules && asset.modules.length === 0 && !assetLoading" class="container-fluid text-center">
      <div class="lead mt-5">No modules yet? Click the plus icon on the left menu to add one!</div>
    </div>
    <div class="container-fluid" *ngIf="assetLoading">
      <p class="placeholder-glow">
        <span class="placeholder w-25"></span>
        <br />
        <span class="placeholder w-25"></span>
        <br />
        <span class="placeholder w-75"></span>
        <br />
        <span class="placeholder w-75"></span>
      </p>
    </div>
  </div>
</div>

<app-modal #addModuleModal modalId="addModuleModal" (formSubmit)="addModule($event)"></app-modal>

<div class="modal fade" id="updateModuleModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <form class="needs-validation" (ngSubmit)="updateModule()">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Update Module</h1>
          <button (click)="closeUpdateModuleModal()" type="button" class="btn-close" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-1">
            <label for="module-update-name" class="col-form-label">Name*</label>
            <input [(ngModel)]="editModule.name" type="text" class="form-control" name="module-update-name" id="module-update-name" required="" />
          </div>
          <div *ngIf="editModule.type !== ModuleTypeDto.CAMERA" class="mb-1">
            <label for="module-update-guid" class="col-form-label">GUID</label>
            <input [(ngModel)]="editModule.parsedConfig.guid" type="text" class="form-control" name="module-update-guid" id="module-update-guid" />
          </div>
          <div *ngIf="editModule.type === ModuleTypeDto.CAMERA" class="mb-1">
            <label class="col-form-label">Source*</label>
            <select [(ngModel)]="editModule.parsedConfig.source" name="module-update-source" class="form-select">
              <option value="WEBRTC">WebRTC</option>
              <option value="NVR">NVR</option>
            </select>
          </div>
          <div *ngIf="editModule.type === ModuleTypeDto.CAMERA && editModule.parsedConfig.source === 'NVR'" class="mb-1">
            <label for="module-update-address" class="col-form-label">Address*</label>
            <input
              [(ngModel)]="editModule.parsedConfig.address"
              type="text"
              class="form-control"
              name="module-update-address"
              placeholder="https://module1.dotocean.eu/"
              id="module-update-address"
              required=""
            />
          </div>
          <div *ngIf="editModule.type === ModuleTypeDto.CAMERA && editModule.parsedConfig.source === 'WEBRTC'" class="mb-3">
            <label for="module-update-id" class="col-form-label">ID*</label>
            <input
              [(ngModel)]="editModule.parsedConfig.id"
              type="text"
              class="form-control"
              name="module-update-id"
              placeholder="Camera ID"
              id="module-update-id"
              required=""
            />
          </div>
        </div>
        <div class="modal-footer">
          <button *ngIf="!showConfirm" (click)="showConfirm = true" type="button" class="btn btn-outline-danger">Remove</button>

          <button *ngIf="showConfirm" (click)="removeModule()" type="button" class="btn btn-danger">Confirm?</button>
          <button *ngIf="showConfirm" (click)="showConfirm = false" type="button" class="btn btn-outline-secondary">Cancel</button>

          <button
            *ngIf="!showConfirm && activeTheme === 'light'"
            [disabled]="editModule.name === '' || editModule.name === null"
            type="submit"
            class="btn btn-primary"
          >
            Save Module
          </button>

          <button
            *ngIf="!showConfirm && activeTheme === 'dark'"
            [disabled]="editModule.name === '' || editModule.name === null"
            type="submit"
            class="btn btn-light"
          >
            Save Module
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal fade" id="enlargeThumbnailModel" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <img [src]="'data:image/jpeg;base64, ' + selectedImage" alt="" />
    </div>
  </div>
</div>

<div class="modal fade" id="clipModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div style="height: 320px" class="modal-content">
      <!-- <iframe style="width: 100%; height: 100%;" [src]="clipURL"></iframe> -->
    </div>
  </div>
</div>

<div class="modal fade" id="consoleModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Terminal</h1>
        <button (click)="closeConsoleModal()" type="button" class="btn-close" aria-label="Close"></button>
      </div>
      <div style="height: 500px">
        <ng-terminal #term></ng-terminal>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="loadingModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div style="width: 100%">
      <div class="d-flex justify-content-center">
        <div class="spinner-grow" role="status"></div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="updateAssetModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content" *ngIf="editAsset && asset">
      <form class="needs-validation" (ngSubmit)="updateAsset()">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Update {{ asset.type! | firstLetterCapital }}</h1>
          <button (click)="closeUpdateAssetModal()" type="button" class="btn-close" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="asset-name" class="col-form-label">Name*</label>
            <input [(ngModel)]="editAsset.name" type="text" class="form-control" name="asset-name" id="asset-name" required="" />
          </div>
          <div class="mb-3">
            <label for="asset-uuid" class="col-form-label">UUID</label>
            <input [(ngModel)]="editAsset.uuid" type="number" class="form-control" name="asset-uuid" id="asset-uuid" />
          </div>
          <div *ngIf="groups.length > 1" class="mb-3">
            <label class="col-form-label">Group</label>
            <select name="asset-group" [(ngModel)]="editAsset.groupId" class="form-select" aria-label="Select group">
              <option *ngFor="let group of groups" [value]="group.Id">{{ group.Name }} ({{ group.Id }})</option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button *ngIf="!showConfirm" (click)="showConfirm = true" type="button" class="btn btn-outline-danger">Remove</button>

          <button *ngIf="showConfirm" (click)="removeAsset()" type="button" class="btn btn-danger">Confirm?</button>
          <button *ngIf="showConfirm" (click)="showConfirm = false" type="button" class="btn btn-outline-secondary">Cancel</button>

          <button *ngIf="!showConfirm && activeTheme === 'light'" [disabled]="!editAsset.name" type="submit" class="btn btn-primary">
            Save {{ asset.type! | firstLetterCapital }}
          </button>
          <button *ngIf="!showConfirm && activeTheme === 'dark'" [disabled]="!editAsset.name" type="submit" class="btn btn-light">
            Save {{ asset.type! | firstLetterCapital }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
