<form class="needs-validation" (ngSubmit)="updateAsset()">
  <div class="modal-header">
    <h1 class="modal-title fs-5" id="exampleModalLabel">Update {{ editAsset.type! | firstLetterCapital }}</h1>
    <button (click)="activeModal.close(false)" type="button" class="btn-close" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div class="mb-3">
      <label for="asset-name" class="col-form-label">Name*</label>
      <input [(ngModel)]="editAsset.name" type="text" class="form-control" name="asset-name" id="asset-name" required="" />
    </div>
    <div class="mb-3">
      <label for="asset-uuid" class="col-form-label">UUID</label>
      <input [(ngModel)]="editAsset.uuid" type="number" class="form-control" name="asset-uuid" id="asset-uuid" />
    </div>
    <div *ngIf="groups.length > 1" class="mb-3">
      <label class="col-form-label">Group</label>
      <select name="asset-group" [(ngModel)]="editAsset.groupId" class="form-select" aria-label="Select group">
        <option *ngFor="let group of groups" [value]="group.id">{{ group.name }} ({{ group.id }})</option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="!showConfirm" (click)="showConfirm = true" type="button" class="btn btn-outline-danger">Remove</button>

    <button *ngIf="showConfirm" (click)="removeAsset()" type="button" class="btn btn-danger">Confirm?</button>
    <button *ngIf="showConfirm" (click)="showConfirm = false" type="button" class="btn btn-outline-secondary">Cancel</button>

    <button
      *ngIf="!showConfirm"
      [disabled]="!editAsset.name"
      type="submit"
      class="btn"
      [ngClass]="(themeService.activeTheme$ | async) === 'light' ? 'btn-primary' : 'btn-light'"
      [disabled]="isSaving"
    >
      Save {{ editAsset.type! | firstLetterCapital }}
    </button>
  </div>
</form>
