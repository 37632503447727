import { Component, Input, OnInit } from '@angular/core';
import { FleetManagerComponents } from '../../../shared/fleetmanager-components.module';
import { ThemeService } from '../../../services/theme-service';
import { IsActiveMatchOptions, NavigationEnd, Router, UrlTree } from '@angular/router';
import { tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'li[sidebar-item]',
  standalone: true,
  imports: [FleetManagerComponents],
  templateUrl: './sidebar-item.component.html',
  styleUrl: './sidebar-item.component.scss',
  host: {
    class: 'nav-item', // Automatically adds the 'nav-item' class to the <li>
  },
})
export class SidebarItemComponent implements OnInit {
  @Input({ required: true })
  public title!: string;

  @Input({ required: true }) routerLink: string | any[] | UrlTree | null | undefined;

  @Input({ required: true })
  public isCollapsed = false;

  @Input()
  public iconLight?: string;

  @Input()
  public iconDark?: string;

  @Input()
  public badgeCount?: number;

  @Input()
  public bootstrapIcon?: string;

  @Input()
  public faIcon?: string;

  public isSelected = false;

  public activeTheme = 'light';

  constructor(themeService: ThemeService, private readonly router: Router) {
    themeService.activeTheme$.subscribe((theme) => {
      this.activeTheme = theme;
    });
  }

  ngOnInit() {
    this.checkIfSelected();

    // Subscribe to router events to detect route changes
    this.router.events
      .pipe(
        untilDestroyed(this),
        tap((event) => {
          if (event instanceof NavigationEnd) {
            this.checkIfSelected();
          }
        })
      )
      .subscribe();
  }

  private isActiveMatchOptions: IsActiveMatchOptions = {
    paths: 'exact', // or 'subset' if you want partial matching
    queryParams: 'subset',
    fragment: 'ignored',
    matrixParams: 'ignored',
  };
  private checkIfSelected() {
    // Create a UrlTree from the routerLink
    const targetUrlTree = this.router.createUrlTree(Array.isArray(this.routerLink) ? this.routerLink : [this.routerLink || '']);

    this.isSelected = this.router.isActive(targetUrlTree, this.isActiveMatchOptions);
  }
}
