<div class="monitor-status row">
  @for (status of statusses(); track $index) {
  <div class="col-3">
    <div class="monitor-status-item">
      <div class="monitor-status-item--group">
        <i icon class="monitor-status-item--icon fa-solid" [ngClass]="status.icon"></i>
        <div class="monitor-status-item--title">{{ status.title }}</div>
      </div>
      <div class="monitor-status-item--status">
        <i
        [ngClass]="status?.status?.toString()?.toLowerCase()"
        [class.color-normal]="status?.status === DeviceStatusType.Normal"
        [class.color-warning]="status?.status === DeviceStatusType.Warn"
        [class.color-danger]="status?.status === DeviceStatusType.Error"
        class="status-icon fa-solid fa-circle list-item--status"></i>
      </div>
    </div>
  </div>
  }
</div>
