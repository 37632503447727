import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { WebrtcService } from '../../services/webrtc-service';
import { FleetManagerComponents } from '../../shared/fleetmanager-components.module';
import { FunctionsUsingCSI, NgTerminal, NgTerminalModule } from 'ng-terminal';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { ModalComponent } from '../modal/modal.component';
import { JoystickEvent, NgxJoystickComponent } from '../../shared/ngx-joystick/ngx-joystick.component';
import { JoystickManagerOptions } from 'nipplejs';
import moment from 'moment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { RadarWebSocketService } from '../../services/radar-websocket-service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigApiService } from '../../services/config-api-service';
import { CameraApiService } from '../../services/camera-api-service';
import { ObjectsApiService } from '../../services/objects-api-service';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';
import { NotificationService } from '../../services/shared-service';
import { NVRApiService } from '../../services/nvr-service';
import { ToastService } from '../../services/toast-service';
import { MqttService } from '../../services/mqtt-service';
import { CerebroApiService } from '../../services/cerebro-api-service';
import { of, tap } from 'rxjs';
import { ThemeService } from '../../services/theme-service';
import { Group } from '../../models/cerebro/group';
import { AssetClient, Asset, CreateModuleRequest, ModuleTypeDto } from '../../services/apis/cloud.service';
import { AssetExtended, fromAsset } from '../../models/asset-extended';
import { ModuleExtended } from '../../models/module-extended';
// eslint-disable-next-line no-var
declare var bootstrap: any;

@Component({
  selector: 'app-asset-detail',
  standalone: true,
  providers: [WebrtcService],
  imports: [FleetManagerComponents, NgTerminalModule, MonacoEditorModule, ModalComponent],
  templateUrl: './detail.component.html',
  styleUrl: './detail.component.scss',
})
export class AssetDetailComponent implements AfterViewInit, OnDestroy {
  @ViewChild('term', { static: false }) child?: NgTerminal;
  @ViewChild('joystick') staticJoystick!: NgxJoystickComponent;
  @ViewChild('addModuleModal') addModuleModal!: ModalComponent;

  public ModuleTypeDto = ModuleTypeDto;

  public joystickOptions: JoystickManagerOptions = {
    mode: 'static',
    position: { bottom: '80px', right: '50%' },
    color: '#86D9F0',
  };

  public asset?: AssetExtended;
  public editAsset?: Asset;
  public selectedTab = 'submodules';
  public selectedModule?: ModuleExtended;
  public editModule: any = { name: '', parsedConfig: {}, type: '' };
  // public editorOptions = { theme: 'vs-dark', language: 'yaml' };
  public editorOptions = {
    theme: 'vs-dark',
    language: 'json',
    automaticLayout: true,
    formatOnPaste: true,
    formatOnType: true,
  };
  public updateModuleModal: any;
  public updateAssetModal: any;
  public enlargeThumbnailModal: any;
  public loadingModal: any;
  public consoleModal: any;
  public clipModal: any;
  public subModuleLoading = false;
  public groups: Group[] = [];
  public configs: any = [];
  public moment: any = moment;
  public selectedImage = '';
  public selectedEventId = '';
  public modulesVisible = true;
  public totalPages = 0;
  public nvrURL: SafeResourceUrl;
  public clipURL: SafeResourceUrl;
  public numberOfDaysStored = 25;
  public assetLoading = false;
  public showConfirm = false;
  readonly prompt = '\n' + FunctionsUsingCSI.cursorColumn(1) + '$ ';

  public activeTheme = 'light';

  constructor(
    private readonly assetsClient: AssetClient,
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigApiService,
    private cameraService: CameraApiService,
    private objectsService: ObjectsApiService,
    public authService: AtlantisNgxAuthService,
    private notificationService: NotificationService,
    public nvrService: NVRApiService,
    private sanitizer: DomSanitizer,
    private toastService: ToastService,
    private mqttService: MqttService,
    private radarSocketService: RadarWebSocketService,
    private webrtcService: WebrtcService,
    private cerebroService: CerebroApiService,
    public readonly themeService: ThemeService
  ) {
    this.loadAsset();

    themeService.activeTheme$.subscribe((theme) => {
      this.activeTheme = theme;
    });

    const vis = localStorage.getItem('modulesVisible');
    if (vis != null) this.modulesVisible = vis == 'true';
  }

  private loadAsset() {
    this.assetLoading = true;

    this.cerebroService.groups$.pipe(tap((groups) => (this.groups = groups))).subscribe();

    this.assetsClient.getAsset(this.route.snapshot.params['id']).subscribe(async (response) => {
      this.asset = fromAsset(response);

      if (this.asset.modules) {
        for (const module of this.asset.modules) {
          if (module.type === ModuleTypeDto.CAMERA) await this.getEvents(module);
          if (module.type === ModuleTypeDto.RADAR) if (!module.radarSocket) this.connectToRadar(module);
        }
      }

      if (this.asset.modules.length > 0) this.selectModule(this.asset.modules[0]);

      setTimeout(() => {
        const tooltipTriggerList: any = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));
      }, 200);

      this.assetLoading = false;
    });
  }

  private connectToRadar(module: any) {
    module.radarSocket = this.radarSocketService.createRadarSocket(module.parsedConfig.guid);
    module.radarSocket.ImageChanged.subscribe((data: any) => (module.radarImage = data.img));
    module.radarSocket.connect();
  }

  private async getConfigs(module: ModuleExtended) {
    if (module.type === ModuleTypeDto.CAMERA) {
      this.editorOptions.language = 'json';

      try {
        const config = JSON.stringify(await this.nvrService.getConfig(module.parsedConfig), null, 2);
        module.selectedConfigFile = { content: config };
      } catch {
        module.selectedConfigFile = { content: '' };
      }
    } else if (module.guid != null) {
      this.editorOptions.language = 'yaml';
      module.configFiles = await this.configService.getConfigFiles(module.guid);

      if (module.configFiles && module.configFiles.length > 0) module.selectedConfigFile = module.configFiles[0];
      else module.selectedConfigFile = { content: '' };
    }
  }

  private async getEvents(module: any) {
    if (module.guid != null) {
      module.selectedEventPage = 1;
      this.cameraService.getEvents(module.guid).subscribe((result) => {
        module.events = result;
        module.events.sort((a: any, b: any) => b.timestamp - a.timestamp);
        this.updateEventsList(module);
      });
    }
  }

  public updateEventsList(module: any) {
    module.shownEvents = module.events.slice((module.selectedEventPage - 1) * 5, module.selectedEventPage * 5);
  }

  get pageNumbers(): number[] {
    if (!this.selectedModule) return [0];

    const halfRange = 4;
    this.totalPages = Math.ceil((this.selectedModule.events?.length ?? 1) / 5);
    let start = Math.max(1, this.selectedModule.selectedEventPage - halfRange);
    const end = Math.min(start + 8, this.totalPages);

    if (end === this.totalPages) start = Math.max(1, end - 8);

    const pageNumbers = Array.from({ length: Math.min(9, this.totalPages) }, (_, i) => start + i);
    return pageNumbers;
  }

  public async saveAndPush() {
    //TODO: WHERE TF DOES .guid come from???
    if (!this.selectedModule || !this.selectedModule.guid) return;

    this.loadingModal.show();

    await this.configService.saveAndPushConfigFiles(this.selectedModule.guid, this.selectedModule.configFiles);

    this.loadingModal.hide();
  }

  public async generateConfigs() {
    //TODO: WHERE TF DOES .guid come from???
    if (!this.selectedModule || !this.selectedModule.guid) return;

    this.loadingModal.show();

    await this.configService.generateConfigsFromTemplates(this.selectedModule.guid, this.selectedModule);
    await this.getConfigs(this.selectedModule);

    this.loadingModal.hide();
  }

  public openAddModuleModal(): void {
    this.addModuleModal.open();
  }

  public openUpdateModuleModal(): void {
    if (!this.selectedModule) return;

    this.editModule = { name: this.selectedModule.name, parsedConfig: this.selectedModule.parsedConfig, type: this.selectedModule.type };
    this.updateModuleModal.show();
  }

  public async openUpdateAssetModal() {
    this.editAsset = { ...this.asset };
    this.updateAssetModal.show();
  }

  public async openEnlargeThumbnailModal() {
    this.enlargeThumbnailModal.show();
  }

  public loadNvrURL(): void {
    if (!this.selectedModule) return;
    this.nvrURL = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.selectedModule.parsedConfig.address}`);
  }

  public toggleModules(): void {
    this.modulesVisible = !this.modulesVisible;
    localStorage.setItem('modulesVisible', this.modulesVisible ? 'true' : 'false');
    this.resizeComponents();
  }

  public openClipModal(eventId: string): void {
    // this.clipURL = this.sanitizer.bypassSecurityTrustResourceUrl(`http://localhost:4200/api/events/${eventId}/clip.mp4`);
    // this.clipURL = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.selectedModule.parsedConfig.address}/api/events/${eventId}/clip.mp4`);
    // this.clipModal.show();
  }

  public async openConsoleModal() {
    // TODO: connect to selectedmodule through ssh
    if (!this.child) return;

    this.child.underlying?.clear();
    this.child.write(FunctionsUsingCSI.cursorColumn(0) + '$ ');

    setTimeout(() => {
      this.child?.underlying?.focus();
    }, 500);

    this.consoleModal.show();
  }

  public addModule(module: ModuleExtended) {
    if (!this.asset?.id) return;

    this.loadingModal.show();

    module.config = JSON.stringify(module.parsedConfig);
    this.assetsClient.createModule(this.asset.id, module as CreateModuleRequest).subscribe((id) => {
      module.id = id;
      this.asset?.modules.push(module);
      this.selectModule(module);

      setTimeout(() => {
        this.loadingModal.hide();
        this.toastService.showToast('Module has been added.');
      }, 500);
    });
  }

  public updateModule() {
    if (!this.asset?.id || !this.selectedModule) return;

    this.closeUpdateModuleModal();
    this.loadingModal.show();

    this.selectedModule.name = this.editModule.name;
    this.selectedModule.parsedConfig = this.editModule.parsedConfig;
    this.selectedModule.config = JSON.stringify(this.editModule.parsedConfig);

    this.updateModule$().subscribe(() => {
      if (!this.asset?.modules || !this.selectedModule) return;
      const index = this.asset!.modules.findIndex((e) => e.id == this.selectedModule!.id);
      this.asset.modules[index] = this.selectedModule;

      switch (this.selectedModule!.type) {
        case ModuleTypeDto.AYB:
          break;
        case ModuleTypeDto.CAMERA:
          if (this.selectedModule!.parsedConfig.source == 'WEBRTC') this.stopVideo();
          if (this.selectedModule!.parsedConfig.source == 'NVR') this.loadNvrURL();
          break;
        case ModuleTypeDto.RADAR:
          if (this.selectedModule!.radarSocket) this.selectedModule!.radarSocket.disconnect();
          this.connectToRadar(this.selectedModule);
          break;
      }

      setTimeout(() => {
        this.loadingModal.hide();
        this.toastService.showToast('Module has been updated.');
      }, 500);
    });
  }

  public updateAsset() {
    if (!this.editAsset || !this.asset) return;

    this.closeUpdateAssetModal();
    this.loadingModal.show();

    const patchOperations = [
      { op: 'replace', path: '/name', value: this.editAsset.name },
      { op: 'replace', path: '/uuid', value: this.editAsset.uuid },
      { op: 'replace', path: '/groupid', value: this.editAsset.groupId },
      { op: 'replace', path: '/owned', value: this.editAsset.owned },
      { op: 'replace', path: '/imageUrl', value: this.editAsset.imageUrl },
    ];

    this.assetsClient.update(this.asset.id!, patchOperations).subscribe(() => {
      this.asset = fromAsset(this.editAsset!);

      setTimeout(() => {
        this.loadingModal.hide();
        this.toastService.showToast('Asset has been updated.');
      }, 500);
    });
  }

  public removeModule() {
    if (!this.asset || !this.selectedModule) return;
    this.closeUpdateModuleModal();
    this.loadingModal.show();

    this.assetsClient.deleteModule(this.asset.id!, this.selectedModule.id!).subscribe(() => {
      this.asset!.modules.splice(this.asset!.modules.indexOf(this.selectedModule!), 1);

      if (this.asset!.modules.length > 0) this.selectModule(this.asset!.modules[0]);
      else this.selectedModule = undefined;

      setTimeout(() => {
        this.loadingModal.hide();
        this.toastService.showToast('Module has been removed.');
      }, 500);
    });
  }

  public async removeAsset() {
    if (!this.asset) return;

    this.closeUpdateAssetModal();
    this.loadingModal.show();

    this.assetsClient.delete(this.asset.id!).subscribe(() => {
      this.notificationService.assetsChanged();

      setTimeout(() => {
        this.loadingModal.hide();
        this.toastService.showToast('Asset has been removed.');

        var url = this.asset?.type?.toLocaleLowerCase();
        if (url !== 'stationary') url += 's';

        this.router.navigate([url]);
      }, 500);
    });
  }

  public async addSubModule(submodule: string) {
    if (!this.selectedModule) return;

    this.subModuleLoading = true;

    this.selectedModule.parsedConfig.subModules[submodule] = true;
    this.selectedModule.config = JSON.stringify(this.selectedModule.parsedConfig);

    this.updateModule$().subscribe(() => (this.subModuleLoading = false));
  }

  private updateModule$() {
    if (!this.asset || !this.selectedModule) return of();

    const patchOperations = [
      { op: 'replace', path: '/name', value: this.selectedModule.name },
      { op: 'replace', path: '/config', value: this.selectedModule.config },
    ];

    return this.assetsClient.updateModule(this.asset.id!, this.selectedModule.id!, patchOperations);
  }

  public async removeSubModule(submodule: string) {
    if (!this.selectedModule) return;

    this.subModuleLoading = true;

    delete this.selectedModule.parsedConfig.subModules[submodule];
    this.selectedModule.config = JSON.stringify(this.selectedModule.parsedConfig);

    this.updateModule$().subscribe(() => (this.subModuleLoading = false));
  }

  public selectModule(module: ModuleExtended): void {
    this.selectedModule = module;

    switch (this.selectedModule.type) {
      case ModuleTypeDto.AYB:
        this.selectedTab = 'submodules';
        break;
      case ModuleTypeDto.CAMERA:
        this.selectedTab = 'livestream';

        switch (this.selectedModule.parsedConfig.source) {
          case 'WEBRTC':
            setTimeout(() => {
              if (this.selectedModule!.webrtcConnection) {
                const videoElement = document.getElementById('videoElement' + module.id) as HTMLVideoElement;
                this.selectedModule!.webrtcConnection.refreshSrcStream(videoElement);
              }
            }, 500);
            break;
          case 'NVR':
            this.loadNvrURL();
            break;
        }
        break;
      case ModuleTypeDto.RADAR:
        this.selectedTab = 'images';
        break;
    }
  }

  public startVideo() {
    if (!this.selectedModule) return;

    if (!this.mqttService.isConnected) this.mqttService.connect();

    const id = this.selectedModule.id;
    const name = this.selectedModule.parsedConfig.id;
    this.selectedModule.webRtcGuid = this.generateGuid();

    const videoElement = document.getElementById('videoElement' + id) as HTMLVideoElement;

    this.selectedModule.webrtcConnection = this.webrtcService.startConnection(`${name}/${this.selectedModule.webRtcGuid}`, videoElement);
  }

  public stopVideo() {
    if (this.selectedModule?.webrtcConnection) {
      this.webrtcService.stopConnection(`${this.selectedModule.parsedConfig.id}/${this.selectedModule.webRtcGuid}`);
      this.selectedModule.webrtcConnection = undefined;
    }
  }

  private _sendJoystickTimer: any;
  private _joystickData: any;

  public joystickMove(ev: JoystickEvent): void {
    this._joystickData = {
      pan: ev.data.vector.x / 20,
      tilt: ev.data.vector.y / 20,
    };

    if (this._sendJoystickTimer == null)
      this._sendJoystickTimer = setInterval(() => {
        this.selectedModule?.webrtcConnection?.sendMqttMessage({
          rostopic: 'axis_camera/camera/ptz/set_move/relative',
          rosmsg: `{ptz: {pan: ${this._joystickData.pan}, tilt: ${this._joystickData.tilt}, zoom:0.0}, ptz_vel: {pan: 0.0, tilt: 0.0, zoom:0.0}}`,
        });
      }, 200);
  }

  public joystickEnd(): void {
    if (this._sendJoystickTimer != null) {
      clearTimeout(this._sendJoystickTimer);
      this._sendJoystickTimer = null;
    }

    this.selectedModule?.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/set_move/relative',
      rosmsg: '{ptz: {pan: 0.0, tilt: 0.0, zoom: 0.0}, ptz_vel: 0.0, tilt: 0.0, zoom:0.0}}',
    });
  }

  public zoomIn(): void {
    this.selectedModule?.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/set_move/relative',
      rosmsg:
        '{ptz: {pan: 0.0, tilt: 0.0, zoom: 0.03}, ptz_vel: {pan: 0.0, tilt: 0.0, zoom:0.01}}',
    });
  }

  public zoomOut(): void {
    this.selectedModule?.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/set_move/relative',
      rosmsg:
        '{ptz: {pan: 0.0, tilt: 0.0, zoom: -0.03}, ptz_vel: {pan: 0.0, tilt: 0.0, zoom:0.01}}',
    });
  }

  public goToPreset(preset: string): void {
    this.selectedModule?.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/goto_preset',
      rosmsg: `{data: ${preset}}`,
    });
  }

  public activateTour(tour: string): void {
    this.selectedModule?.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/operate_preset_tour',
      rosmsg: `{name: ${tour}, action: start}`,
    });
  }

  public stopTour(): void {
    this.selectedModule?.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/stop',
      rosmsg: `{data: stop}`,
    });
  }

  ngAfterViewInit(): void {
    this.updateModuleModal = new bootstrap.Modal('#updateModuleModal');
    this.updateAssetModal = new bootstrap.Modal('#updateAssetModal');
    this.enlargeThumbnailModal = new bootstrap.Modal('#enlargeThumbnailModel');
    this.loadingModal = new bootstrap.Modal('#loadingModal');
    this.consoleModal = new bootstrap.Modal('#consoleModal');
    this.clipModal = new bootstrap.Modal('#clipModal');

    const input = document.getElementById('module-name');
    const input1 = document.getElementById('module-update-name');
    const input2 = document.getElementById('asset-name');

    const element = document.getElementById('addModuleModal');
    const element1 = document.getElementById('updateModuleModal');
    const element2 = document.getElementById('updateAssetModal');

    element?.addEventListener('shown.bs.modal', () => {
      input?.focus();
    });
    element1?.addEventListener('shown.bs.modal', () => {
      input1?.focus();
    });
    element2?.addEventListener('shown.bs.modal', () => {
      input2?.focus();
    });

    if (!this.child) return;

    this.child.setXtermOptions({
      fontFamily: '"Cascadia Code", Menlo, monospace',
      cursorBlink: true,
    });

    this.child.onData().subscribe((input) => {
      if (!this.child) return;

      if (input === '\r') {
        this.child.write(this.prompt);
      } else if (input === '\u007f') {
        if (this.child.underlying!!.buffer.active.cursorX > 2) {
          this.child.write('\b \b');
        }
      } else if (input === '\u0003') {
        this.child.write('^C');
        this.child.write(this.prompt);
      } else this.child.write(input);
    });
  }

  public resizeComponents(): void {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200);
  }

  public closeUpdateModuleModal(): void {
    this.showConfirm = false;
    this.updateModuleModal.hide();
  }
  public closeUpdateAssetModal(): void {
    this.showConfirm = false;
    this.updateAssetModal.hide();
  }
  public closeEnlargeThumbnailModal(): void {
    this.enlargeThumbnailModal.hide();
  }
  public closeConsoleModal(): void {
    this.consoleModal.hide();
  }
  public closeClipModal(): void {
    this.clipModal.hide();
  }

  private generateGuid(): string {
    return `${this.s4()}${this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}${this.s4()}${this.s4()}`;
  }

  private s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  ngOnDestroy(): void {
    if (this.mqttService != null && this.mqttService.isConnected) this.mqttService.disconnect();

    this.asset?.modules.forEach((module) => {
      if (module.radarSocket) module.radarSocket.disconnect();
      if (module.webrtcConnection) this.webrtcService.stopConnection(`${module.parsedConfig.id}/${module.webRtcGuid}`);
    });
  }
}
