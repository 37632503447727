import { Injectable } from '@angular/core';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';
import { GetObjectsResponse, ObjectClient, SourceTypeColor } from '@dotocean/virtualworld-ngx-services';
import { Observable, shareReplay, switchMap } from 'rxjs';

@Injectable()
export class ObjectsCacheApiService {
  public sourceTypeColors: SourceTypeColor[];
  public SourceTypeColor$: Observable<SourceTypeColor[]>;
  constructor(objectClient: ObjectClient, authService: AtlantisNgxAuthService) {
    this.SourceTypeColor$ = authService.identity$.pipe(
      switchMap((i) => objectClient.getTypesColors(i?.Application.Groups[0] ?? 1, undefined).pipe(shareReplay(1)))
    );

    this.SourceTypeColor$.subscribe((sourceTypeColor) => (this.sourceTypeColors = sourceTypeColor));
  }

  public getShipTypeToDisplay(object: GetObjectsResponse) {
    if(!this.sourceTypeColors) return undefined;
    const filtered = this.sourceTypeColors.filter((s) => s.sourceType === object.type);
    const shipType = object.meta ? +object.meta['SHIP_TYPE'] : undefined;

    let foundStc = filtered.find((f) => f.shipType == shipType);

    if (!foundStc && filtered.length > 0) {
      foundStc = filtered[0];
      if (foundStc) {
        foundStc.shipType = object.meta ? +object.meta['SHIP_TYPE'] : undefined;
      }
    }

    return foundStc;
  }
}
