import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FleetManagerComponents } from '../../shared/fleetmanager-components.module';
import { CameraApiService } from '../../services/camera-api-service';
import { ThemeService } from '../../services/theme-service';
import { VesselClient } from '@dotocean/calypso-ngx-services';
import { ModuleExtended } from '../../models/module-extended';
import { ModuleTypeDto } from '../../services/apis/cloud.service';
import { SourceClient } from '@dotocean/virtualworld-ngx-services';
// eslint-disable-next-line no-var
declare var bootstrap: any;

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [FleetManagerComponents],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent implements OnInit, AfterViewInit {
  @Input() modalId = 'default';
  @Input() title = '';
  @Output() formSubmit = new EventEmitter<ModuleExtended>();
  @ViewChild('modalElement') modalElement!: ElementRef;

  private modalInstance: any;

  public ModuleTypeDto = ModuleTypeDto;

  public editModule: ModuleExtended = { selectedEventPage: 1 };
  public invalidGUID = false;
  public moduleFound = false;
  public guidLoading = false;
  public activeTheme = 'light';

  constructor(
    private readonly vesselClient: VesselClient,
    private readonly sourceClient: SourceClient,
    private cameraService: CameraApiService,
    themeService: ThemeService
  ) {
    themeService.activeTheme$.subscribe((theme) => {
      this.activeTheme = theme;
    });
  }

  ngOnInit(): void {
    this.editModule = {
      selectedEventPage: 1,
      type: ModuleTypeDto.AYB,
      parsedConfig: {
        subModules: { core: true },
        source: 'WEBRTC',
      },
    };
  }

  ngAfterViewInit(): void {
    this.modalInstance = new bootstrap.Modal(this.modalElement.nativeElement);
  }

  public onSubmit(): void {
    this.formSubmit.emit(this.editModule);
    this.close();
  }

  public async checkGUID(ev: any) {
    this.invalidGUID = false;
    this.moduleFound = false;

    if (ev.target.value.length == 36) {
      this.guidLoading = true;

      try {
        if (!this.editModule.type) return;
        switch (this.editModule.type) {
          case ModuleTypeDto.AYB:
            this.vesselClient.getVessel(ev.target.value).subscribe((result) => {
              if (result != null) this.moduleFound = true;
              else this.invalidGUID = true;
            });
            break;
          case ModuleTypeDto.RADAR:
            this.sourceClient.getSources(undefined).subscribe((result) => {
              const radarInfo = result.find((e: any) => e.sid == ev.target.value);

              if (radarInfo != null) this.moduleFound = true;
              else this.invalidGUID = true;
            });
            break;
          case ModuleTypeDto.CAMERA:
            this.cameraService.getSingleCamera(ev.target.value).subscribe((result) => {
              if (result != null) this.moduleFound = true;
              else this.invalidGUID = true;
            });
            break;
        }
      } catch {
        this.invalidGUID = true;
      }

      this.guidLoading = false;
    }
  }

  open(): void {
    if (this.modalInstance) this.modalInstance.show();
  }

  close(): void {
    if (this.modalInstance) this.modalInstance.hide();
  }
}
